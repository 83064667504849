export default {
  currencies(state) {
    return state.currencies;
  },
  feeFrequencies(state) {
    return state.feeFrequencies;
  },
  budgetQuote(state) {
    return state.budgetQuote;
  },
};
