function monthDayFormat(stringDate) {
  const prettyDate = new Date(stringDate);
  console.log(stringDate);
  return prettyDate.toLocaleDateString("en-us", {
    month: "short",
    day: "numeric",
  });
}

function monthYearFormat(stringDate) {
  const prettyDate = new Date(stringDate);
  return prettyDate.toLocaleDateString("en-us", {
    month: "long",
    year: "numeric",
  });
}

function monthDayYearFormat(stringDate) {
  const prettyDate = new Date(stringDate);
  return prettyDate.toLocaleDateString("en-us", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

function weekdayMonthDayFormatWithTimezoneOffset(dateString) {
  const prettyDate = new Date(dateString);
  return prettyDate.toLocaleDateString("en-us", {
    weekday: "long",
    month: "short",
    day: "numeric",
  });
}

module.exports = {
  monthDayFormat,
  monthYearFormat,
  monthDayYearFormat,
  weekdayMonthDayFormatWithTimezoneOffset,
};
