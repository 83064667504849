import axios from "axios";
import { headers, handleError } from "../../utils/axiosUtil";

export default {
  loadSessionDetails({ commit, rootGetters }) {
    return axios
      .get("/accounts/" + rootGetters.accountId + "/user", headers())
      .then((res) => {
        const data = res.data;
        commit("setAccount", data.account);
        commit("setUser", data.user);
      })
      .catch((err) => handleError(err));
  },
  loadAccountUsers({ commit, rootGetters }) {
    return axios
      .get("/accounts/" + rootGetters.accountId, headers())
      .then((res) => {
        commit("setAccountUsers", res.data);
      })
      .catch((err) => handleError(err));
  },
  addUser({ rootGetters, commit }, payload) {
    return axios
      .post("/accounts/" + rootGetters.accountId + "/user", payload, headers())
      .then((_) => {
        commit("addAccountUser", payload);
      })
      .catch((err) => handleError(err));
  },
  clearSessionDetails({ commit }) {
    commit("setAccount", {});
    commit("setUser", {});
    commit("setAccountUsers", []);
  },
  updateUser({ rootGetters, dispatch }, payload) {
    return axios
      .put("/accounts/" + rootGetters.accountId + "/user", payload, headers())
      .then(() => {
        dispatch("notifyUser", {
          message: "Account Updated",
          level: "success",
        });
        dispatch("loadSessionDetails");
      })
      .catch((err) => handleError(err));
  },
  updateAccount({ rootGetters, dispatch }, payload) {
    return axios
      .put("/accounts/" + rootGetters.accountId, payload, headers())
      .then(() => {
        dispatch("notifyUser", {
          message: "Account Updated",
          level: "success",
        });
        dispatch("loadSessionDetails");
      })
      .catch((err) => handleError(err));
  },
};
