<template>
  <div @click="toggle()">
    <div v-show="collapsed" id="openButton" class="menu-button">
      <fa-icon icon="bars" size="2x"></fa-icon>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("sideBarModule", ["collapsed"]),
  },
  methods: {
    toggle() {
      this.$store.dispatch("sideBarModule/toggle");
    },
  },
};
</script>

<style scoped>
.menu-button {
  display: block;
  z-index: 5;
  position: fixed;
  top: 5px;
  padding: 15px;
}

#openButton {
  right: 15px;
}

#closeButton {
  right: 240px;
}
</style>
