import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: false,
  state() {
    return {
      ledger: {},
      expenseDetail: {},
      incomeDetail: {},
      filterOptions: {
        dateRange: [],
        userIds: [],
        budgetCategoryIds: [],
        chargeOptionIds: [],
        amount: null,
        checkExpenses: true,
        incomeSourceIds: [],
        checkIncome: true,
        taxDeductable: null,
      },
    };
  },
  mutations,
  actions,
  getters,
};
