<template>
  <div v-if="!loading">
    <div class="table-title">
      <h2>Filter My Ledger</h2>
    </div>
    <main class="page-content-wrapper">
      <div class="form-group row">
        <label for="selectTime" class="col-sm-2 col-form-label">View:</label>
        <div class="col-sm-10">
          <datepicker
            v-model="dateRange"
            :clearable="false"
            :enableTimePicker="false"
            :testInput="false"
            :weekStart="0"
            :autoApply="true"
            :disabledDates="disabledDateSelection"
            range
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="amount" class="col-sm-2 col-form-label">Amount:</label>
        <div class="col-sm-10">
          <currency-input :useBootstrap="true" id="amount" v-model="amount" />
        </div>
      </div>
      <div class="form-group row" v-show="hasCategoriesAndJobs">
        <label for="type" class="col-sm-2 col-from-label">Entry Types:</label>
        <div class="col-sm-1">
          <b-form-radio-group
            class="form-check-inline"
            id="type"
            v-model="checkElement"
            :options="checkMethods"
          />
        </div>
      </div>
      <div class="form-group row" v-show="multipleAccountUsers">
        <label for="users" class="col-sm-2 col-form-label"
          >Entered By: {{ selectedUsers }}</label
        >
        <div class="col-sm-10">
          <b-form-select
            class="form-control"
            id="users"
            v-model="userIds"
            :options="accountUsers"
            value-field="user_id"
            text-field="user_name"
            :select-size="2"
            multiple
          >
          </b-form-select>
        </div>
      </div>
      <div class="form-group row" v-show="checkExpenses">
        <label for="budgetCategory" class="col-sm-2 col-form-label"
          >Budget Categories: {{ selectedBudgetCategories }}</label
        >
        <div class="col-sm-10">
          <b-form-select
            class="form-control"
            id="budgetCategory"
            v-model="budgetCategoryIds"
            :options="expenseCategories"
            value-field="id"
            text-field="name"
            :select-size="5"
            multiple
          >
          </b-form-select>
        </div>
      </div>
      <div class="form-group row" v-show="checkExpenses">
        <label for="chargeOption" class="col-sm-2 col-form-label"
          >Payment Methods: {{ selectedChargeMethods }}</label
        >
        <div class="col-sm-10">
          <b-form-select
            class="form-control"
            id="chargeOption"
            v-model="chargeOptionIds"
            :options="chargeOptions"
            value-field="id"
            text-field="name"
            :select-size="3"
            multiple
          >
          </b-form-select>
        </div>
      </div>
      <div class="form-group row" v-show="checkExpenses">
        <label for="tax" class="col-sm-2 col-from-label">Taxation:</label>
        <div class="col-sm-1">
          <b-form-radio-group
            class="form-check-inline"
            id="tax"
            v-model="taxable"
            :options="taxMethods"
          />
        </div>
      </div>
      <div class="form-group row" v-show="checkIncome">
        <label for="job" class="col-sm-2 col-form-label"
          >Jobs: {{ selectedIncomeSources }}</label
        >
        <div class="col-sm-10">
          <b-form-select
            class="form-control"
            id="job"
            v-model="incomeSourceIds"
            :options="incomeSources"
            value-field="id"
            text-field="name"
            :select-size="3"
            multiple
          >
          </b-form-select>
        </div>
      </div>
    </main>
    <page-buttons
      v-if="hasMadeChanges"
      rightButtonName="Apply"
      leftButtonName="Close"
      @lbClick="$emit('done')"
      @rbClick="applyFilter"
    />
    <page-buttons v-else leftButtonName="Close" @lbClick="$emit('done')" />
  </div>
  <page-loading v-else />
</template>

<script>
import { mapGetters } from "vuex";
import { BFormSelect, BFormRadioGroup } from "bootstrap-vue-3";
import CurrencyInput from "../base_ui/CurrencyInput.vue";
import PageButtons from "../layout/PageButtons.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: {
    PageButtons,
    CurrencyInput,
    Datepicker,
    BFormSelect,
    BFormRadioGroup,
  },
  emits: ["done"],
  data() {
    return {
      dateRange: [],
      userIds: [],
      budgetCategoryIds: [],
      chargeOptionIds: [],
      incomeSourceIds: [],
      loading: false,
      amount: null,
      checkMethods: [
        { text: "All", value: "both" },
        { text: "Expenses", value: "expense" },
        { text: "Earnings", value: "income" },
        // { text: "Neither", value: "neither", disabled: true },
      ],
      checkElement: "both",
      taxMethods: [
        { text: "Both", value: "all" },
        { text: "Deductable", value: "yes" },
        { text: "NonDeductable", value: "no" },
      ],
      taxable: "all",
    };
  },
  computed: {
    ...mapGetters([
      "storedFilterOptions",
      "incomeSources",
      "expenseCategories",
      "chargeOptions",
      "multipleAccountUsers",
      "accountUsers",
    ]),
    checkIncome() {
      return (
        this.incomeSources.length > 0 &&
        (this.checkElement === "income" || this.checkElement === "both")
      );
    },
    checkExpenses() {
      return (
        this.expenseCategories.length > 0 &&
        (this.checkElement === "expense" || this.checkElement === "both")
      );
    },
    taxDeductable() {
      if (this.taxable === "yes") {
        return true;
      } else if (this.taxable === "no") {
        return false;
      } else {
        return null;
      }
    },
    hasMadeChanges() {
      return (
        this.userIds !== this.storedFilterOptions.userIds ||
        this.dateRange !== this.storedFilterOptions.dateRange ||
        this.budgetCategoryIds !== this.storedFilterOptions.budgetCategoryIds ||
        this.chargeOptionIds !== this.storedFilterOptions.chargeOptionIds ||
        this.amount !== this.storedFilterOptions.amount ||
        this.checkExpenses !== this.storedFilterOptions.checkExpenses ||
        this.incomeSourceIds !== this.storedFilterOptions.incomeSourceIds ||
        this.checkIncome !== this.storedFilterOptions.checkIncome ||
        this.taxDeductable !== this.storedFilterOptions.taxDeductable
      );
    },
    selectedUsers() {
      if (this.userIds.length > 0) {
        return "(" + this.userIds.length + ")";
      } else {
        return "(All)";
      }
    },
    selectedBudgetCategories() {
      if (this.budgetCategoryIds.length > 0) {
        return "(" + this.budgetCategoryIds.length + ")";
      } else {
        return "(All)";
      }
    },
    selectedChargeMethods() {
      if (this.chargeOptionIds.length > 0) {
        return "(" + this.chargeOptionIds.length + ")";
      } else {
        return "(All)";
      }
    },
    selectedIncomeSources() {
      if (this.incomeSourceIds.length > 0) {
        return "(" + this.incomeSourceIds.length + ")";
      } else {
        return "(All)";
      }
    },
    hasCategoriesAndJobs() {
      return this.expenseCategories.length > 0 && this.incomeSources.length > 0;
    },
    earliestDate() {
      var earliestDate = new Date();
      if (this.checkExpenses) {
        if (this.budgetCategoryIds.length > 0) {
          for (const catDate of this.expenseCategories) {
            if (
              this.budgetCategoryIds.includes(catDate.id) &&
              catDate.earliest_entry !== null
            ) {
              let earliestEntry = new Date(catDate.earliest_entry);
              earliestEntry.setHours(0, 0, 0, 0);
              earliestDate =
                earliestEntry < earliestDate ? earliestEntry : earliestDate;
            }
          }
        } else {
          for (const catDate of this.expenseCategories) {
            if (catDate.earliest_entry !== null) {
              let earliestEntry = new Date(catDate.earliest_entry);
              earliestEntry.setHours(0, 0, 0, 0);
              earliestDate =
                earliestEntry < earliestDate ? earliestEntry : earliestDate;
            }
          }
        }
      }
      if (this.checkIncome) {
        if (this.incomeSourceIds.length > 0) {
          for (const sorDate of this.incomeSources) {
            if (
              this.incomeSourceIds.includes(sorDate.id) &&
              sorDate.earliest_entry !== null
            ) {
              let earliestEntry = new Date(sorDate.earliest_entry);
              earliestEntry.setHours(0, 0, 0, 0);
              earliestDate =
                earliestEntry < earliestDate ? earliestEntry : earliestDate;
            }
          }
        } else {
          for (const sorDate of this.incomeSources) {
            if (sorDate.earliest_entry !== null) {
              let earliestEntry = new Date(sorDate.earliest_entry);
              earliestEntry.setHours(0, 0, 0, 0);
              earliestDate =
                earliestEntry < earliestDate ? earliestEntry : earliestDate;
            }
          }
        }
      }
      return earliestDate;
    },
  },
  async created() {
    await this.setFilterOptions(this.storedFilterOptions);
    this.loading = true;
    await Promise.all([
      this.$store.commit("setFilterOptions", this.getFilterOptions()),
      this.$store.dispatch("loadAccountUsers"),
      this.$store.dispatch("loadSessionDetails"),
      this.$store.dispatch("loadChargeOptions"),
      this.$store.dispatch("loadExpenseCategories"),
      this.$store.dispatch("loadIncomeSources"),
    ]);
    this.loading = false;
  },
  methods: {
    async applyFilter() {
      await this.$store.dispatch("loadLedgerHistory", this.getFilterOptions());
      this.$store.commit("setFilterOptions", this.getFilterOptions());
      this.$emit("done");
      this.$store.dispatch("notifyUser", {
        message: "Ledger Filtered",
        level: "info",
      });
    },
    disabledDateSelection(d) {
      const date = new Date(d);
      return date > new Date() || date < new Date(this.earliestDate);
    },
    setFilterOptions(storedFilterOptions) {
      this.userIds = storedFilterOptions.userIds;
      this.budgetCategoryIds = storedFilterOptions.budgetCategoryIds;
      this.chargeOptionIds = storedFilterOptions.chargeOptionIds;
      this.incomeSourceIds = storedFilterOptions.incomeSourceIds;
      this.dateRange = storedFilterOptions.dateRange;
      this.amount = storedFilterOptions.amount;
      if (this.dateRange.length === 0) {
        let ninetyDaysAgo = new Date(
          new Date().setDate(new Date().getDate() - 90)
        );
        ninetyDaysAgo.setHours(0, 0, 0, 0);
        this.dateRange = [ninetyDaysAgo, new Date()];
      }
      if (!storedFilterOptions.checkIncome) {
        this.checkElement = "expense";
      } else if (!storedFilterOptions.checkExpenses) {
        this.checkElement = "income";
      } else {
        this.checkElement = "both";
      }
      if (storedFilterOptions.taxDeductable === true) {
        this.taxable = "yes";
      } else if (storedFilterOptions.taxDeductable === false) {
        this.taxable = "no";
      } else {
        this.taxable = "all";
      }
    },
    getFilterOptions() {
      return {
        dateRange: this.dateRange,
        userIds: this.userIds,
        budgetCategoryIds: this.budgetCategoryIds,
        chargeOptionIds: this.chargeOptionIds,
        amount: this.amount,
        checkExpenses: this.checkExpenses,
        incomeSourceIds: this.incomeSourceIds,
        checkIncome: this.checkIncome,
        taxDeductable: this.taxDeductable,
      };
    },
  },
};
</script>

<style scoped>
.page-content-wrapper {
  top: 80px;
}
</style>
