export default {
  setSessionExpired(state, expiredSession) {
    state.sessionExpired = expiredSession;
  },
  setSessionData(state, sessionData) {
    state.authCode = sessionData.authCode;
    state.accountId = sessionData.accountId;
    state.userId = sessionData.userId;
  },
  setJat(state, jat) {
    state.jat = jat;
  },
  setFpt(state, fpt) {
    state.fpt = fpt;
  },
};
