<template>
  <section>
    <div>
      <h2>Sign up</h2>
      <h6>For an account to budget your bucks!</h6>
    </div>
    <form @submit.prevent="submitForm" autocomplete="on">
      <div class="auth-input">
        <input
          type="name"
          id="name"
          placeholder="Name"
          v-model.trim="userName"
          :class="{ 'is-invalid': v$.userName.$error }"
          autocomplete="name"
          ref="userName"
        />
        <div v-if="v$.userName.$error" class="invalid-feedback">
          <span v-if="v$.userName.required">*Name is Required</span>
        </div>
      </div>
      <div class="auth-input">
        <input
          type="email"
          id="email"
          placeholder="Email"
          v-model.trim="email"
          :class="{ 'is-invalid': v$.email.$error }"
          autocomplete="email"
        />
        <div v-if="v$.email.$error" class="invalid-feedback">
          <span v-if="v$.email.email">*Email is Not Valid</span>
          <span v-else-if="v$.email.required">*Email is Required</span>
        </div>
      </div>
      <div class="auth-input">
        <input
          type="password"
          id="pass1"
          placeholder="Password"
          v-model.trim="password"
          :class="{ 'is-invalid': v$.password.$error }"
          autocomplete="new-password"
        />
        <div v-if="v$.password.$error" class="invalid-feedback">
          <span v-if="v$.password.minLength"
            >*Password must be at least 6 characters</span
          >
          <span v-else-if="v$.password.required">*Password is Required</span>
        </div>
      </div>
      <div class="auth-input">
        <input
          type="password"
          id="pass2"
          placeholder="Confirm Password"
          v-model.trim="confirmPassword"
          :class="{ 'is-invalid': v$.confirmPassword.$error }"
          autocomplete="off"
        />
      </div>
      <div v-if="v$.confirmPassword.$error" class="invalid-feedback">
        <span v-if="v$.confirmPassword.sameAsPassword"
          >*Passwords do not match</span
        >
        <span v-else-if="v$.confirmPassword.required"
          >*Must confirm password</span
        >
      </div>
      <base-button type="submit">Submit</base-button>
    </form>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  name: "SignUp",
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  mounted() {
    this.$refs.userName.focus();
  },
  data() {
    return {
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
    };
  },
  emits: ["formSubmitted"],
  validations() {
    return {
      userName: { required },
      email: { required, email },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
    };
  },
  computed: {
    ...mapGetters(["joiningAccount"]),
  },
  methods: {
    async submitForm() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        this.$emit("formSubmitted");
        if (!this.joiningAccount) {
          await this.$store.dispatch("signUp", {
            userName: this.userName,
            email: this.email,
            password: this.password,
            confirmPassword: this.confirmPassword,
          });
        } else {
          await this.$store.dispatch("joinAccount", {
            userName: this.userName,
            email: this.email,
            password: this.password,
            confirmPassword: this.confirmPassword,
          });
        }
        this.$router.push("/profile");
      }
    },
  },
};
</script>

<style scoped>
.auth-input {
  padding-top: 5px;
  padding-bottom: 8px;
}

select,
option,
input {
  outline: 0;
  border: 0;
  background: transparent;
  border-bottom: 1px solid var(--black);
  max-width: 500px;
  margin: auto;
  display: block;
  width: 100%;
  font: inherit;
}

select,
input::placeholder {
  padding-left: 8px;
  color: var(--black);
}

select:focus,
option:focus,
input:focus {
  background-color: var(--sand);
  outline: none;
}

.is-invalid {
  border-color: var(--text-invalid);
  background-color: var(--focus-invalid);
}

.invalid-feedback {
  display: block;
  color: var(--text-invalid);
}
</style>
