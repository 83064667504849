import axios from "axios";
import { handleError } from "../../utils/axiosUtil";
import storageManager from "../../utils/localStorageManager";
import router from "../../../router/index.js";

export default {
  signIn({ commit, dispatch }, payload) {
    return axios
      .post("/accounts/signIn", payload)
      .then((res) => {
        commit("setSessionData", res.data);
        storageManager.setLocalStorage(res.data);
        storageManager.startSessionTimer(commit, dispatch);
        if (res.data && res.data.previousLoginDate) {
          dispatch("notifyUser", {
            message: "Welcome back, " + res.data.userName,
            level: "dark",
          });
        }
      })
      .catch((err) => handleError(err, commit));
  },
  signUp({ commit, dispatch }, payload) {
    return axios
      .post("/accounts/signUp", payload)
      .then((res) => {
        commit("setSessionData", res.data);
        storageManager.setLocalStorage(res.data);
        storageManager.startSessionTimer(commit, dispatch);
      })
      .catch((err) => handleError(err, commit));
  },
  forgotPassword({ commit }, payload) {
    return axios
      .post("/accounts/forgotPassword", payload)
      .catch((err) => handleError(err, commit));
  },
  changePassword({ dispatch, commit }, payload) {
    return axios
      .put("/accounts/", payload)
      .then(() => {
        dispatch("notifyUser", {
          message: "Password has been changed",
          level: "success",
        });
      })
      .catch((err) => handleError(err, commit));
  },
  joinAccount({ commit, dispatch, getters }, payload) {
    return axios
      .post("/accounts/join", {
        userName: payload.userName,
        email: payload.email,
        password: payload.password,
        confirmPassword: payload.confirmPassword,
        jat: getters.jat,
      })
      .then((res) => {
        commit("setSessionData", res.data);
        dispatch("notifyUser", {
          message: "Welcome! You've joined " + res.data.accountName,
          level: "success",
        });
        storageManager.setLocalStorage(res.data);
        storageManager.startSessionTimer(commit, dispatch);
      })
      .catch((err) => handleError(err, commit));
  },
  checkJat({ commit, dispatch }, payload) {
    return axios
      .post("/accounts/checkToken", {
        tempToken: payload,
      })
      .then((res) => {
        commit("setJat", res.data.refreshedToken);
      })
      .catch((err) => {
        handleError(err, commit);
        commit("setJat", "");
      });
  },
  checkFpt({ commit }, payload) {
    return axios
      .post("/accounts/checkToken", {
        tempToken: payload,
      })
      .then((res) => {
        commit("setFpt", res.data.refreshedToken);
      })
      .catch((err) => {
        commit("setFpt", "");
        handleError(err, commit);
      });
  },
  logout({ commit, dispatch }) {
    router.push("/");
    commit("setSessionData", {
      accountId: "",
      authCode: "",
      userId: "",
    });
    dispatch("clearSessionDetails");
    storageManager.clearLocalStorage();
    storageManager.clearSessionTimer();
    dispatch("notifyUser", {
      message: "You've logged out",
      level: "info",
    });
  },
  checkSession({ commit, dispatch }) {
    const sessionData = storageManager.getLocalStroage();
    if (sessionData.accountId && sessionData.authCode && sessionData.userId) {
      storageManager.startSessionTimer(commit, dispatch);
      commit("setSessionData", sessionData);
    }
  },
};
