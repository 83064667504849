const { v4: uuidv4 } = require("uuid");
export default {
  setAccount(state, account) {
    state.account = account;
  },
  setUser(state, user) {
    state.user = user;
  },
  setAccountUsers(state, accountUsers) {
    state.accountUsers = accountUsers;
  },
  addAccountUser(state, invitedUser) {
    state.accountUsers.push({
      user_id: uuidv4(),
      invitor: state.user.user_name,
      user_name: "(invited)",
      email: invitedUser.email,
      created_date: new Date().toLocaleString("sv-SE"),
    });
  },
};
