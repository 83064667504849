<template>
  <div>
    <the-header />
    <blured-background :blur="!collapsed" @click="tryClose" />
    <div>
      <RouterView v-slot="{ Component }">
        <Transition name="route" mode="out-in">
          <component :is="Component"></component>
        </Transition>
      </RouterView>
    </div>
  </div>
</template>

<script>
import TheHeader from "./components/layout/TheHeader.vue";
import BluredBackground from "./components/layout/BluredBackground.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    TheHeader,
    BluredBackground,
  },
  computed: {
    ...mapGetters([
      "authCode",
      "accountId",
      "sessionAutoExpired",
      "pageRenderable",
    ]),
    ...mapGetters("sideBarModule", ["collapsed"]),
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    tryClose() {
      if (!this.collapsed) {
        this.$store.dispatch("sideBarModule/toggle");
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      this.$store.dispatch("adjustWindowWidth", this.windowWidth);
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || "Budgeted Buck";
    },
    sessionAutoExpired(autoLogedOut) {
      if (autoLogedOut && this.$route.meta.requiresAuth) {
        this.$store.dispatch("notifyUser", {
          messag: "Looks like you need to log in!",
          level: "info",
        });
        this.$router.push({
          path: "/auth",
          query: {
            redirect: this.$route.path,
          },
        });
      }
    },
  },
  created() {
    this.$store.dispatch("checkSession");
  },
};
</script>

<style>
:root {
  --brown: #b0a48a;
  --light-green: #beceb0;
  --sand: #f1efe9;
  --green: #34857f;
  --blue: #335484;
  --red: #843339;
  --white: rgb(255, 255, 255);
  --white-transparent: rgba(255, 255, 255, 0.8);
  --shadow: rgba(0, 0, 0, 0.25);
  --black: black;
  --gradient: linear-gradient(to top, var(--sand) 0%, white 100%);
  --text-invalid: red;
  --focus-invalid: rgb(248, 211, 211);
  --radius: 0.3em;
}

html {
  font-family: Arial, Helvetica, sans-serif;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  background: linear-gradient(to top, var(--sand) 0%, white 100%);
}

h5 {
  text-align: left;
}

p {
  text-align: center;
  display: inline-block;
  font-weight: bold;
}
</style>
