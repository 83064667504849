import store from "../index";
import state from "../index";

const headers = () => {
  return {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Cache-Control": "no-cache",
      Authorization: state.getters.authorization,
      "x-accountid": state.getters.accountId,
      "x-userid": state.getters.userId,
      "x-multiuser": state.getters.multipleAccountUsers,
      "x-request-locale-time": new Date().toLocaleString("sv-SE"),
    },
  };
};

const handleError = (axiosError) => {
  if (
    axiosError &&
    axiosError.response &&
    axiosError.response.data &&
    axiosError.response.data.Error
  ) {
    const error = axiosError.response.data.Error;
    switch (error.classification) {
      case "DatabaseQueryError":
      case "DatabaseTransactionError":
      case "InvalidInternalInputError":
      case "UnableToCreateResource":
      case "UnverifiableRelationship":
        store.dispatch("notifyUser", {
          message: "Encountered an Unexpected Error",
          level: "danger",
        });
        setTimeout(() => {
          store.dispatch("notifyUser", {
            message: "Unable to complete your request",
            level: "warning",
          });
        }, 1000);
        break;
      case "UnauthorizedUserError":
        store.dispatch("notifyUser", {
          message: "You're not authorized to perform this action",
          level: "warning",
        });
        break;
      case "ExpiredTokenError":
        store.dispatch("notifyUser", {
          message: "Sorry, this invitation has expried",
          level: "warning",
        });
        break;
      case "ResourceNotFoundError":
        store.dispatch("notifyUser", {
          message: error.message,
          level: "warning",
        });
        break;
      case "InvalidTokenError":
      case "UnauthenticatedUserError":
        //no need to send a message for these errors
        break;
      case "InvalidUserInputError":
      default:
        store.dispatch("notifyUser", {
          message: error.message,
          level: "danger",
        });
    }
    console.error(error);
  } else {
    console.error(axiosError);
    // todo: make this better

    // store.dispatch("notifyUser", {
    //   message: "Encountered an Unexpected Error",
    //   level: "danger",
    // });
    // setTimeout(() => {
    //   store.dispatch("notifyUser", {
    //     message: "Unable to complete your request",
    //     level: "warning",
    //   });
    // }, 1500);
  }
};

const prepareLedgerFilter = (unpreped) => {
  let request = { ...unpreped };
  if (request.dateRange && request.dateRange.length > 0) {
    request.dateRange = [
      new Date(
        new Date(request.dateRange[0]).setHours(0, 0, 0, 0)
      ).toLocaleString("sv-SE"),
      new Date(request.dateRange[1]).toLocaleString("sv-SE"),
    ];
  } else {
    const currentDate = new Date();
    let ninetyDaysAgo = new Date(
      new Date().setDate(currentDate.getDate() - 90)
    );
    ninetyDaysAgo.setHours(0, 0, 0, 0);
    request.dateRange = [
      ninetyDaysAgo.toLocaleString("sv-SE"),
      currentDate.toLocaleString("sv-SE"),
    ];
  }
  request.amount = Math.abs(request.amount);
  return request;
};

export { headers, handleError, prepareLedgerFilter };
