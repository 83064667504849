<template>
  <main v-if="loaded">
    <div class="bg"></div>
    <div class="bg bg2"></div>
    <div class="bg bg3"></div>
    <div class="content">
      <section>
        <div>
          <h1>Password Reset</h1>
          <h5>Change My Password</h5>
        </div>
        <form @submit.prevent="submitPasswordUpdate">
          <div class="form-control">
            <input
              ref="password"
              type="password"
              id="pass1"
              placeholder="New Password"
              v-model.trim="password"
              :class="{ 'is-invalid': v$.password.$error }"
              autocomplete="new-password"
            />
            <div v-if="v$.password.$error" class="invalid-feedback">
              <span v-if="v$.password.minLength"
                >*Password must be at least 6 characters</span
              >
              <span v-else-if="v$.password.required"
                >*Password is Required</span
              >
            </div>
          </div>
          <div class="form-control">
            <input
              type="password"
              id="pass2"
              placeholder="Confirm Password"
              v-model.trim="confirmPassword"
              :class="{ 'is-invalid': v$.confirmPassword.$error }"
              autocomplete="off"
            />
            <div v-if="v$.confirmPassword.$error" class="invalid-feedback">
              <span v-if="v$.confirmPassword.sameAsPassword"
                >*Passwords do not match</span
              >
              <span v-else-if="v$.confirmPassword.required"
                >*Must confirm password</span
              >
            </div>
          </div>
          <base-button type="submit">Update</base-button>
        </form>
      </section>
    </div>
  </main>
  <page-loading v-else />
</template>
<script>
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
    };
  },
  async created() {
    const queryFpt = this.$route.query.fpt;
    if (typeof queryFpt !== "undefined") {
      this.$router.push({
        path: "/profile/changePassword",
      }); //make url prettier
      await this.$store.dispatch("checkFpt", queryFpt);
      if (this.fpt === "") {
        this.$store.dispatch("notifyUser", {
          message: "Sorry, this link has expired.",
          level: "dark",
        });
        this.$router.push({
          path: "/",
        });
      }
    }
  },
  mounted() {
    this.$refs.password.focus();
  },
  data() {
    return {
      loaded: true,
      password: "",
      confirmPassword: "",
    };
  },
  computed: {
    ...mapGetters(["fpt", "isAuthenticated"]),
  },
  methods: {
    submitPasswordUpdate() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else if (this.isAuthenticated) {
        this.loaded = false;
        this.$store.dispatch("changePassword", {
          password: this.password,
          confirmPassword: this.confirmPassword,
        });
        this.$router.push("/profile/accountDetails");
      } else {
        this.loaded = false;
        this.$store.dispatch("changePassword", {
          fpt: this.fpt,
          password: this.password,
          confirmPassword: this.confirmPassword,
        });
        this.$router.push("/signIn");
      }
    },
  },
};
</script>

<style scoped>
.bg {
  animation: slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(
    -60deg,
    /* rgb(192, 53, 35) 50%,
    rgb(110, 45, 105) 50% */ var(--brown) 50%,
    var(--sand) 50%
  );
  bottom: 0;
  left: -50%;
  opacity: 0.5;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: -1;
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 4s;
}

.bg3 {
  animation-duration: 5s;
}

.content {
  background-color: var(--white-transparent);
  border-radius: var(--radius);
  border: solid 2px var(--green);
  box-shadow: 0 0 0.25em var(--shadow);
  box-sizing: border-box;
  left: 50%;
  padding: 7vmin;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 250px;
  min-height: 300px;
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}

.form-control {
  padding-top: 5px;
  padding-bottom: 8px;
}

select,
option,
input {
  outline: 0;
  border: 0;
  background: transparent;
  border-bottom: 1px solid var(--black);
  max-width: 500px;
  margin: auto;
  display: block;
  width: 100%;
  font: inherit;
}

select,
input::placeholder {
  padding-left: 8px;
  color: var(--black);
}

select:focus,
option:focus,
input:focus {
  background-color: var(--sand);
  outline: none;
}

.is-invalid {
  border-color: var(--text-invalid);
  background-color: var(--focus-invalid);
}

.invalid-feedback {
  color: var(--text-invalid);
}
</style>
