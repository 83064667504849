import axios from "axios";
import { headers, handleError } from "../../utils/axiosUtil";

export default {
  loadExpenseCategories({ commit, rootGetters }) {
    return axios
      .get(
        "/accounts/" + rootGetters.accountId + "/expenses/categories",
        headers()
      )
      .then((res) => {
        commit("setExpenseCategories", res.data);
      })
      .catch((err) => handleError(err));
  },
  saveExpenseCategory({ dispatch, rootGetters }, payload) {
    return axios
      .post(
        "/accounts/" + rootGetters.accountId + "/expenses/categories",
        {
          category: payload.name,
          description: payload.description,
        },
        headers()
      )
      .then(() => {
        dispatch("notifyUser", {
          message: "Budget Category Saved",
          level: "success",
        });
        dispatch("loadExpenseCategories");
      })
      .catch((err) => handleError(err));
  },
  deleteExpenseCategory({ dispatch, rootGetters }, payload) {
    return axios
      .patch(
        "/accounts/" +
          rootGetters.accountId +
          "/expenses/categories/" +
          payload.id,
        {
          deactive: true,
        },
        headers()
      )
      .then((res) => {
        dispatch("loadExpenseCategories");
        dispatch("notifyUser", {
          message: "Budget Category Deleted",
          level: "dark",
        });
        if (res.data.fundAdjustment > 0) {
          setTimeout(() => {
            dispatch("notifyUser", {
              message:
                "Your unbugdeted funds has been adjusted by " +
                res.data.fundAdjustment,
              level: "info",
            });
          }, 2000);
        }
      })
      .catch((err) => handleError(err));
  },
  budgetCategories({ dispatch, rootGetters }, payload) {
    return axios
      .put(
        "/accounts/" + rootGetters.accountId + "/budgetCategories",
        payload,
        headers()
      )
      .then(() => {
        dispatch("notifyUser", {
          message: "You budgeted $" + payload.budgetTotal,
          level: "success",
        });
        Promise.all([
          dispatch("loadExpenseCategories"),
          dispatch("loadLastBudgetContributions"),
          dispatch("loadSessionDetails"),
        ]);
      })
      .catch((err) => handleError(err));
  },
  loadLastBudgetContributions({ commit, rootGetters }) {
    return axios
      .get(
        "/accounts/" + rootGetters.accountId + "/budgetCategories",
        headers()
      )
      .then((res) => {
        commit("setLastContributions", res.data.contributions);
      })
      .catch((err) => handleError(err));
  },
};
