<template>
  <div v-if="loaded">
    <div class="table-title">
      <h2>Budget Allocations</h2>
      <div>
        Total:
        <accounting-formated :amount="totalAllocated" />
      </div>
    </div>
    <main class="page-content-wrapper">
      <ul id="responsive-table">
        <li class="table-header">
          <div class="column column-1">Category</div>
          <div class="column column-2">Available</div>
          <div class="column column-3" v-show="showDescirption">
            Description
          </div>
          <div class="column column-4" v-show="showDate">Added</div>
          <div class="column column-5">Delete</div>
        </li>
        <li class="table-row" v-for="row in expenseCategories" :key="row.id">
          <div class="column column-1">
            {{ row.name }}
          </div>
          <div class="column column-2">
            <accounting-formated :amount="row.available_funds" />
          </div>
          <div class="column column-3" v-show="showDescirption">
            {{ row.description }}
          </div>
          <div class="column column-4" v-show="showDate">
            {{ monthYearFormat(row.created_date) }}
          </div>
          <div class="column column-5">
            <fa-icon icon="trash" @click="deleteElement(row.id)"></fa-icon>
          </div>
        </li>
        <li class="table-row" v-show="showAddRow">
          <input
            ref="nameInput"
            class="row-input column column-1"
            type="text"
            v-model.trim="name"
            placeholder="Category"
          />
          <input
            class="row-input column column-3"
            type="text"
            v-model.trim="description"
            v-show="showDescirption"
            placeholder="Optional Description"
          />
          <div class="column column-4" v-show="showDate">{{ currentDate }}</div>
          <div class="column column-5">
            <fa-icon icon="trash" @click="closeAdd"></fa-icon>
          </div>
        </li>
      </ul>
    </main>
    <page-buttons :rightButtonName="buttonLabel" @rbClick="handleClick" />
  </div>
  <page-loading v-else />
</template>
<script>
import { mapGetters } from "vuex";
import { monthYearFormat } from "../../../scriptUtils";
import AccountingFormated from "../../../components/base_ui/AccountingFormated.vue";

export default {
  components: {
    AccountingFormated,
  },
  async created() {
    this.loaded = false;
    await Promise.all([
      this.$store.dispatch("loadSessionDetails"),
      this.$store.dispatch("loadExpenseCategories"),
    ]);
    this.loaded = true;
  },
  data() {
    return {
      loaded: false,
      showAddRow: false,
      name: "",
      description: null,
    };
  },
  computed: {
    ...mapGetters(["expenseCategories", "windowWidth"]),
    showDescirption() {
      return this.windowWidth > 525;
    },
    showDate() {
      return this.windowWidth > 850;
    },
    currentDate() {
      const date = new Date();
      return date.toLocaleDateString("en-us", {
        month: "long",
        year: "numeric",
      });
    },
    hasMadeChanges() {
      return this.name !== "" || this.description !== null;
    },
    buttonLabel() {
      if (this.showAddRow) {
        return "Save Category";
      } else {
        return "Add Category";
      }
    },
    totalAllocated() {
      let total = 0;
      for (const cat of this.expenseCategories) {
        total += parseFloat(cat.available_funds);
      }
      return total;
    },
  },
  methods: {
    monthYearFormat,
    handleClick() {
      if (this.showAddRow) {
        this.save();
      } else {
        this.showAddRow = true;
        setTimeout(() => this.$refs.nameInput.focus(), 0);
      }
    },
    save() {
      if (this.hasMadeChanges) {
        if (this.name === "") {
          this.$store.dispatch("notifyUser", {
            message: "Category name is required",
            level: "danger",
          });
        } else {
          this.loaded = false;
          this.$store.dispatch("saveExpenseCategory", {
            name: this.name,
            description: this.description,
          });
          this.closeAdd();
        }
      } else {
        this.$store.dispatch("notifyUser", {
          message: "you've not made any changes",
          level: "warning",
        });
      }
    },
    deleteElement(id) {
      if (confirm("Are you sure you want to delete this category?")) {
        this.loaded = false;
        this.$store.dispatch("deleteExpenseCategory", {
          id: id,
        });
      }
      this.$router.go();
    },
    closeAdd() {
      this.showAddRow = false;
      this.name = "";
      this.description = null;
      this.loaded = true;
    },
  },
};
</script>
<style scoped>
.column-1 {
  flex-basis: 20%;
  text-align: left;
}
.column-2 {
  flex-basis: 30%;
}
.column-3 {
  flex-basis: 35%;
}
.column-4 {
  flex-basis: 20%;
}
.column-5 {
  flex-basis: 8%;
}
</style>
