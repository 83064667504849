<template>
  <div v-if="loaded">
    <div>
      <div class="table-title">
        <h2>Recurring Expenses</h2>
        <small>Automatically added to your ledger</small>
      </div>
      <main class="page-content-wrapper">
        <ul id="responsive-table">
          <li class="table-header">
            <div class="column column-1">Category</div>
            <div class="column column-2" v-show="showFrequency">Frequency</div>
            <div class="column column-3" v-show="showCategory">Cost</div>
            <div class="column column-4" v-show="showNotes">Notes</div>
            <div class="column column-5" v-show="showChargeOption">
              Charged to
            </div>
            <div class="column column-6" v-if="showDeleteLabel">Delete</div>
            <div class="column column-6" v-else></div>
          </li>
          <li
            class="table-row"
            v-for="row in recurringExpenses"
            :key="row.expense_id"
            @click.self="showExpenseDetail(row.expense_id)"
          >
            <div class="column column-1" v-show="showCategory">
              {{ row.category }}
            </div>
            <div class="column column-2" v-show="showFrequency">
              {{ row.frequency }}
            </div>

            <div class="column column-3">
              <accounting-formated :amount="row.cost" />
            </div>
            <div class="column column-4" v-show="showNotes">
              {{ row.notes }}
            </div>
            <div class="column column-5" v-show="showChargeOption">
              {{ row.charge_option }}
            </div>
            <div class="column column-6">
              <fa-icon
                icon="trash"
                @click="deleteElement(row.expense_id)"
              ></fa-icon>
            </div>
          </li>
        </ul>
      </main>
      <page-buttons
        leftButtonName="Add"
        rightButtonName="Earnings"
        @lbClick="lbClick"
        @rbClick="rbClick"
      />
      <pop-up
        v-if="showExpenseDetails"
        @close="closeDetailView"
        #default="{ closed }"
      >
        <expense-details :expenseId="detailId" @done="closed" />
      </pop-up>
    </div>
  </div>
  <page-loading v-else />
</template>
<script>
import { mapGetters } from "vuex";
import PopUp from "../../components/pop_up/PopUp.vue";
import ExpenseDetails from "../../components/pop_up/ExpenseDetails.vue";
import AccountingFormated from "../../components/base_ui/AccountingFormated.vue";

export default {
  components: {
    PopUp,
    ExpenseDetails,
    AccountingFormated,
  },
  async created() {
    this.loaded = false;
    await Promise.all([
      this.$store.dispatch("loadSessionDetails"),
      this.$store.dispatch("loadRecurringExpenses"),
    ]);
    this.loaded = true;
  },
  data() {
    return {
      loaded: false,
      detailId: "",
      showExpenseDetails: false,
    };
  },
  computed: {
    ...mapGetters(["recurringExpenses", "windowWidth"]),
    showNotes() {
      return this.windowWidth > 700;
    },
    showChargeOption() {
      return this.windowWidth > 600;
    },
    showDeleteLabel() {
      return this.windowWidth > 370;
    },
    showFrequency() {
      return this.windowWidth > 515;
    },
    showCategory() {
      return this.windowWidth > 300;
    },
  },
  methods: {
    deleteElement(id) {
      if (confirm("Are you sure you want to delete? ")) {
        this.$store.dispatch("deleteExpense", {
          id: id,
          recuring: true,
        });
      }
    },
    rbClick() {
      this.$router.push("/recurring/earnings");
    },
    lbClick() {
      this.$router.push({
        path: "/expense",
        query: {
          recurring: true,
        },
      });
    },
    showExpenseDetail(expenseId) {
      this.closeDetailView();
      this.detailId = expenseId;
      this.showExpenseDetails = true;
    },
    closeDetailView() {
      this.showExpenseDetails = false;
      this.detailId = "";
    },
  },
};
</script>
<style scoped>
.column-1 {
  flex-basis: 35%;
  flex-direction: column;
  white-space: nowrap;
  align-items: flex-start;
}
.column-2 {
  flex-basis: 20%;
}
.column-3 {
  flex-basis: 15%;
}
.column-4 {
  flex-basis: 30%;
}
.column-5 {
  flex-basis: 15%;
}
.column-6 {
  flex-basis: 8%;
}
</style>
