<template>
  <header>
    <section class="alert-wrapper">
      <TransitionGroup name="alert" mode="out-in">
        <div v-for="alert in alerts" :key="alert.id">
          <b-alert
            class="alert-element"
            :show="alertAlive(alert.id)"
            :variant="alert.variant"
            >{{ alert.message }}</b-alert
          >
        </div>
      </TransitionGroup>
    </section>

    <Transition>
      <side-bar-button />
    </Transition>
    <Transition>
      <the-side-bar v-show="!collapsed" />
    </Transition>
  </header>
</template>

<script>
import TheSideBar from "./TheSideBar.vue";
import SideBarButton from "./SideBarButton.vue";
import { BAlert } from "bootstrap-vue-3";
import { mapGetters } from "vuex";

export default {
  components: {
    TheSideBar,
    SideBarButton,
    BAlert,
  },
  computed: {
    ...mapGetters("sideBarModule", ["collapsed"]),
    ...mapGetters(["notification"]),
  },
  data() {
    return {
      alertId: 0,
      alerts: [],
    };
  },
  watch: {
    notification() {
      this.addAlert(this.notification);
    },
  },
  methods: {
    addAlert(alert) {
      if (alert.message) {
        const id = this.alertId + 1;
        this.alertId = id;
        this.alerts.push({
          id: id,
          variant: this.getVariant(alert.level),
          message: alert.message,
        });
        setTimeout(() => {
          this.removeAlert(id);
        }, 5500);
      }
    },
    getVariant(level) {
      var variant;
      switch (level.toLowerCase()) {
        //case 'primary':
        //case 'secondary':
        case "success":
        case "danger":
        case "warning":
        case "info":
        //case 'light':
        case "dark":
          variant = level;
          break;
        default:
          variant = "info";
          break;
      }
      return variant;
    },
    removeAlert(id) {
      this.alerts = this.alerts.filter((a) => {
        return a.id !== id;
      });
    },
    alertAlive(id) {
      return this.alerts.some((a) => a.id === id);
    },
  },
};
</script>

<style scoped>
.alert-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  top: 0;
  left: 0;
  z-index: 25;
}
.alert-element {
  margin: 15px;
  width: fit-content;
}

.alert-move,
.alert-leave-active,
.alert-enter-active {
  transition: all 0.5s ease;
}
.alert-enter-from {
  transform: translateX(-100%);
  opacity: 0.5;
}
.alert-leave-to {
  opacity: 0;
}

.v-enter-from {
  transform: translateX(225px);
}

.v-enter-active {
  transition: all 0.4s ease-in;
}

.v-enter-to {
  transform: translateX(0);
}

.v-leave-from {
  transform: translateX(0);
}

.v-leave-active {
  transition: all 0.2s ease-out;
}

.v-leave-to {
  transform: translateX(225px);
}
</style>
