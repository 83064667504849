<template>
  <div class="wrap">
    <p class="label">{{ label }}</p>
    <label class="switch">
      <input type="checkbox" v-model="switchOn" @click="toggle" />
      <span class="slider" />
    </label>
  </div>
</template>
<script>
export default {
  props: {
    on: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
  },
  emits: ["switch"],
  data() {
    return {
      switchOn: this.on,
    };
  },
  methods: {
    toggle() {
      this.switchOn = !this.switchOn;
      this.$emit("switch", this.switchOn);
    },
  },
};
</script>
<style scoped>
.label {
  display: inline-block;
  margin: 2px;
  padding-right: 12px;
  bottom: 12px;
  align-items: center;
  text-align: justify;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--light-green);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--green);
}

input:focus + div {
  box-shadow: 0 0 1px var(--green);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
</style>
