export default {
  expenseCategories(state) {
    return state.expenseCategories;
  },
  budgetCategories(state) {
    return state.expenseCategories;
  },
  hasBudgetedBefore(state) {
    return state.lastBudgetContributions.length > 0;
  },
  lastBudgetContributions(state) {
    return state.lastBudgetContributions;
  },
};
