export default {
  account(state) {
    return state.account;
  },
  accountName(state) {
    return state.account.account_name;
  },
  accountDescription(state) {
    return state.account.description;
  },
  betaFunctionalityEnabled(state) {
    return state.account.betaFunctionalityEnabled;
  },
  totalUnbudgetedFunds(state) {
    return state.account.totalUnbudgetedFunds;
  },
  hasFundsToBudget(state) {
    return parseFloat(state.account.totalUnbudgetedFunds).toFixed(2) > 0;
  },
  accountUsers(state) {
    return state.accountUsers;
  },
  accountCreatedDate(state) {
    return state.account.createdDate;
  },
  memberNames(state) {
    var names = "";
    for (const member of state.accountUsers) {
      if (names == "") {
        names += member.user_name;
      } else {
        names += ", " + member.user_name;
      }
    }
    return names;
  },
  accountUserIds(state) {
    return state.account.user_ids;
  },
  user(state) {
    return state.user;
  },
  userName(state) {
    return state.user.user_name;
  },
  email(state) {
    return state.user.email;
  },
  allowsNotifications(state) {
    return state.user.allowsNotifications;
  },
  currency(state) {
    return state.user.currencyDefault;
  },
  multipleAccountUsers(state) {
    if (typeof state.accountUsers !== "undefined") {
      return state.accountUsers.length > 1;
    } else if (typeof state.account.user_ids !== "undefined") {
      return state.account.user_ids.length > 1;
    } else return false;
  },
};
