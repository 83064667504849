<template>
  <div v-if="blur" class="backdrop"></div>
</template>

<script>
export default {
  props: {
    blur: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 4;
}
</style>
