export default {
  accountId(state) {
    return state.accountId;
  },
  userId(state) {
    return state.userId;
  },
  authCode(state) {
    return state.authCode;
  },
  authorization(state) {
    return "Bearer " + state.authCode;
  },
  isAuthenticated(state) {
    return state.authCode !== "" && state.authCode !== null;
  },
  sessionAutoExpired(state) {
    return state.sessionExpired;
  },
  joiningAccount(state) {
    return (
      state.jat !== null && typeof state.jat !== "undefined" && state.jat !== ""
    );
  },
  jat(state) {
    return state.jat;
  },
  fpt(state) {
    return state.fpt;
  },
};
