<template>
  <div>
    <div class="table-title">
      <h2>
        {{ titleSuffix }}<small v-show="showSubTitle">{{ subTitle }}</small>
      </h2>
    </div>
    <main class="page-content-wrapper">
      <ul id="responsive-table">
        <li class="table-header">
          <div class="column column-1" v-show="useCol1">Added By</div>
          <div class="column column-2">{{ nameLabel }}</div>
          <div class="column column-3" v-show="showDescirption">
            Description
          </div>
          <div class="column column-4" v-show="showDate">{{ col4Title }}</div>
          <div class="column column-5">Delete</div>
        </li>
        <li class="table-row" v-for="row in rows" :key="row.id">
          <div class="column column-1" v-show="useCol1">
            {{ row.user_name }}
          </div>
          <div class="column column-2">{{ row.name }}</div>
          <div class="column column-3" v-show="showDescirption">
            {{ row.description }}
          </div>
          <div class="column column-4" v-show="showDate">
            {{ monthYearFormat(row.created_date) }}
          </div>
          <div class="column column-5">
            <fa-icon
              icon="trash"
              @click="deleteElement(row.id, row.name)"
            ></fa-icon>
          </div>
        </li>
        <li class="table-row" v-show="showAddRow">
          <input
            ref="nameInput"
            class="row-input column column-2"
            type="text"
            v-model.trim="name"
            :placeholder="nameLabel"
          />
          <input
            class="row-input column column-3"
            type="text"
            v-model.trim="description"
            v-show="showDescirption"
            placeholder="Optional Description"
          />
          <div class="column column-4" v-show="showDate">{{ currentDate }}</div>
          <div class="column column-5">
            <fa-icon icon="trash" @click="closeAdd"></fa-icon>
          </div>
        </li>
      </ul>
    </main>
    <page-buttons :rightButtonName="buttonLabel" @rbClick="handleClick" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { monthYearFormat } from "../../scriptUtils";

export default {
  props: {
    titleSuffix: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
    },
    rows: {
      type: Object,
      required: true,
    },
    resourceType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showAddRow: false,
      name: "",
      description: null,
    };
  },
  computed: {
    ...mapGetters(["userId", "windowWidth"]),
    showSubTitle() {
      return typeof subTitle !== "undefined";
    },
    useCol1() {
      // for (const row of this.rows) {
      //   if (String(row.user_id) !== String(this.userId)) {
      //     return true;
      //   }
      // }
      return false;
    },
    saveMethod() {
      switch (this.resourceType) {
        case "expenseCategories":
          return "saveExpenseCategory";
        case "chargeOptions":
          return "saveChargeOption";
        case "incomeSources":
          return "saveEarningSource";
        default:
          return "";
      }
    },
    deleteMethod() {
      switch (this.resourceType) {
        case "expenseCategories":
          return "deleteExpenseCategory";
        case "chargeOptions":
          return "deleteChargeOption";
        case "incomeSources":
          return "deleteEarningSource";
        default:
          return "";
      }
    },
    hasMadeChanges() {
      return this.name !== "" || this.description !== null;
    },
    nameLabel() {
      switch (this.resourceType) {
        case "expenseCategories":
          return "Category";
        case "chargeOptions":
          return "Method";
        case "incomeSources":
          return "Job";
        default:
          return "name";
      }
    },
    col4Title() {
      switch (this.resourceType) {
        case "expenseCategories":
          return "Added";
        case "chargeOptions":
          return "Aquried";
        case "incomeSources":
          return "Started";
        default:
          return "name";
      }
    },
    currentDate() {
      const date = new Date();
      return date.toLocaleDateString("en-us", {
        month: "long",
        year: "numeric",
      });
    },
    buttonLabel() {
      if (this.showAddRow) {
        return "Save " + this.nameLabel;
      } else {
        return "Add " + this.nameLabel;
      }
    },
    showDescirption() {
      return this.windowWidth > 525;
    },
    showDate() {
      return this.windowWidth > 650;
    },
  },
  methods: {
    monthYearFormat,
    handleClick() {
      if (this.showAddRow) {
        this.save();
      } else {
        this.showAddRow = true;
        setTimeout(() => this.$refs.nameInput.focus(), 0);
      }
    },
    save() {
      if (this.hasMadeChanges) {
        if (this.name === "") {
          this.$store.dispatch("notifyUser", {
            message: this.nameLabel + " is required",
            level: "danger",
          });
        } else {
          this.$store.dispatch(this.saveMethod, {
            name: this.name,
            description: this.description,
          });
          this.closeAdd();
        }
      } else {
        this.$store.dispatch("notifyUser", {
          message: "you've not made any changes",
          level: "warning",
        });
      }
    },
    closeAdd() {
      this.showAddRow = false;
      this.name = "";
      this.description = null;
    },
    deleteElement(id, name) {
      if (confirm("Are you sure you want to delete " + name + "?")) {
        this.$store.dispatch(this.deleteMethod, {
          id: id,
        });
      }
      //refresh page
      this.$router.go();
    },
  },
};
</script>

<style>
.page-content-wrapper {
  position: absolute;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  top: 100px;
  bottom: var(--pageButtonHeight);
  padding: 3px 27px;
  font-size: 1em;
}

#responsive-table {
  list-style: none;
  margin: 0;
  border: 0;
  font: inherit;
  padding: 2px;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  position: static;
}

.table-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  height: 100px;
  padding: 0;
  text-align: center;
  margin-right: 25px;
  margin-top: 6px;
  z-index: 1;
  overflow: hidden;
  justify-content: center;
  font-variant-caps: all-small-caps;
}

.row-input,
.row-input:active,
.row-input:focus {
  border: none;
  outline: none;
}

li {
  border-radius: 3px;
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.table-header {
  color: var(--white);
  background-color: var(--blue);
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  position: sticky;
  top: -4px;
}
.table-row {
  background-color: var(--white);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}
.table-row:hover,
.table-row:focus {
  border: 2px solid var(--light-green);
}
.column {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: baseline;
}
.column-1 {
  flex-basis: 15%;
}
.column-2 {
  flex-basis: 20%;
  text-align: left;
}
.column-3 {
  flex-basis: 50%;
}
.column-4 {
  flex-basis: 20%;
}
.column-5 {
  flex-basis: 8%;
}
</style>
