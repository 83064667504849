<template>
  <div v-if="loaded">
    <div class="table-title">
      <h2>Budget Funds</h2>
      <small>
        Available:
        <accounting-formated :amount="unbudgetedFunds" />
      </small>
      <small>
        Budgeted:
        <accounting-formated :amount="budgetedFunds" />
      </small>
    </div>
    <main class="page-content-wrapper">
      <ul id="responsive-table">
        <li class="table-header">
          <div class="column column-1">Category</div>
          <div class="column column-2" v-show="showCol2">Saved</div>
          <div class="column column-3">Contribute</div>
        </li>
        <li class="table-row" v-for="category in budget" :key="category.id">
          <div class="column column-1">{{ category.name }}</div>
          <div class="column column-2" v-show="showCol2">
            <accounting-formated :amount="category.saved" />
          </div>
          <div class="column column-3">
            <currency-input
              :rightText="true"
              v-model="category.saving"
              @focusout="checkAvaiableFunds"
            />
          </div>
        </li>
      </ul>
    </main>
    <page-buttons
      v-if="canPreFill"
      leftButtonName="Use last budget amounts"
      @lbClick="preFill"
    />
    <page-buttons
      v-else-if="validBudget"
      rightButtonName="Save Budget"
      @rbClick="saveBudget"
    />
  </div>
  <page-loading v-else />
</template>
<script>
import { mapGetters } from "vuex";
import AccountingFormated from "../../components/base_ui/AccountingFormated.vue";
import CurrencyInput from "../../components/base_ui/CurrencyInput.vue";
import PageButtons from "../../components/layout/PageButtons.vue";

export default {
  components: {
    AccountingFormated,
    CurrencyInput,
    PageButtons,
  },
  async created() {
    this.loaded = false;
    await Promise.all([
      this.$store.dispatch("loadSessionDetails"),
      this.$store.dispatch("loadExpenseCategories"),
      this.$store.dispatch("loadLastBudgetContributions"),
    ]);
    this.init();
    this.loaded = true;
  },
  data() {
    return {
      loaded: false,
      budget: [],
    };
  },
  computed: {
    ...mapGetters([
      "budgetCategories",
      "totalUnbudgetedFunds",
      "hasBudgetedBefore",
      "lastBudgetContributions",
      "windowWidth",
    ]),
    budgetedFunds() {
      var budgetedFunds = 0;
      for (const category of this.budget) {
        if (!isNaN(parseFloat(category.saving))) {
          budgetedFunds =
            parseFloat(budgetedFunds) + parseFloat(category.saving);
        }
      }
      return budgetedFunds.toFixed(2);
    },
    unbudgetedFunds() {
      const unbudgeted = this.totalUnbudgetedFunds - this.budgetedFunds;
      return unbudgeted.toFixed(2);
    },
    canPreFill() {
      return this.hasBudgetedBefore && !this.validBudget;
    },
    validBudget() {
      for (const category of this.budget) {
        if (parseFloat(category.saving) !== 0) {
          return true;
        }
      }
      return false;
    },
    showCol2() {
      return this.windowWidth > 350;
    },
  },
  watch: {
    $route() {
      this.$router.go();
    },
  },
  methods: {
    init() {
      this.budget = [];
      const preFill = this.$route.query.preFill;
      for (const category of this.budgetCategories) {
        this.budget.push({
          id: category.id,
          name: category.name,
          saved: category.available_funds,
          saving: preFill ? this.lastContribution(category.id) : 0,
        });
      }
    },
    lastContribution(categoryId) {
      for (const contr of this.lastBudgetContributions) {
        if (contr.category_id === categoryId) {
          return parseFloat(contr.budget_contribution);
        }
      }
      return 0;
    },
    preFill() {
      this.$router.push({ path: "/budget", query: { preFill: true } });
    },
    async saveBudget() {
      if (parseFloat(this.unbudgetedFunds) >= 0.0) {
        this.loaded = false;
        await this.$store.dispatch("budgetCategories", {
          budgetTotal: this.budgetedFunds,
          categoryDistributions: this.budget,
        });
        this.$router.push("/profile/budgetCategories");
      } else {
        this.$store.dispatch("notifyUser", {
          message: "You've budgeted more funds than you have available",
          level: "danger",
        });
        setTimeout(() => {
          this.$store.dispatch("notifyUser", {
            message:
              "Adjust categories until your total is less than or equal to $" +
              this.totalUnbudgetedFunds,
            level: "info",
          });
        }, 3000);
      }
    },
    checkAvaiableFunds() {
      if (this.unbudgetedFunds < 0) {
        this.$store.dispatch("notifyUser", {
          message: "You've exceeded your avaiable funds",
          level: "warning",
        });
      }
    },
  },
};
</script>
<style scoped>
.column-1 {
  flex-basis: 30%;
  justify-content: flex-start;
  text-align: left;
}
.column-2 {
  flex-basis: 25%;
}
.column-3 {
  flex-basis: 35%;
  justify-content: flex-end;
}
</style>
