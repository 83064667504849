const setLocalStorage = (session) => {
  localStorage.setItem("accountId", session.accountId);
  localStorage.setItem("userId", session.userId);
  localStorage.setItem("authCode", session.authCode);
  const sessionExpiration = new Date().getTime() + session.expiresIn;
  localStorage.setItem("sessionExpiration", sessionExpiration);
};

const getLocalStroage = () => {
  return {
    accountId: localStorage.getItem("accountId"),
    userId: localStorage.getItem("userId"),
    authCode: localStorage.getItem("authCode"),
  };
};

const clearLocalStorage = () => {
  localStorage.removeItem("accountId");
  localStorage.removeItem("userId");
  localStorage.removeItem("authCode");
  localStorage.removeItem("sessionExpiration");
};

var timer;

const startSessionTimer = (commit, dispatch) => {
  commit("setSessionExpired", false);
  const sessionExpiresIn =
    +localStorage.getItem("sessionExpiration") - new Date().getTime();
  timer = setTimeout(() => {
    commit("setSessionExpired", true);
    dispatch("notifyUser", {
      level: "info",
      message: "Your session has expired. You'll need to log in again.",
    });
    dispatch("logout");
  }, sessionExpiresIn);
};

const clearSessionTimer = () => {
  clearTimeout(timer);
};

export default {
  setLocalStorage,
  getLocalStroage,
  clearLocalStorage,
  startSessionTimer,
  clearSessionTimer,
};
