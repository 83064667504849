<template>
  <section>
    <div>
      <h2>Sign In</h2>
      <h6>To budget your bucks!</h6>
    </div>
    <form @submit.prevent="submitForm" autocomplete="on">
      <div class="auth-input">
        <input
          type="email"
          id="email"
          placeholder="Email"
          v-model.trim="email"
          :class="{ 'is-invalid': v$.email.$error }"
          autocomplete="username"
          ref="email"
        />
        <div v-if="v$.email.$error" class="invalid-feedback">
          <span v-if="v$.email.required">*Email is Required</span>
          <span v-else-if="v$.email.email">*Email is not Valid</span>
        </div>
      </div>
      <div class="auth-input">
        <input
          type="password"
          id="pass1"
          placeholder="Password"
          v-model.trim="password"
          :class="{ 'is-invalid': v$.password.$error }"
          autocomplete="current-password"
        />
        <div v-if="v$.password.$error" class="invalid-feedback">
          <span v-if="v$.password.required">*Password is Required</span>
          <span v-else-if="v$.password.minLength"
            >*Password must be at least 6 characters</span
          >
        </div>
      </div>
      <base-button type="submit">Submit</base-button>
    </form>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "SignIn",
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  mounted() {
    this.$refs.email.focus();
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  emits: ["formSubmitted"],
  validations() {
    return {
      email: { required, email },
      password: { required },
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    async submitForm() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        this.$emit("formSubmitted");
        await this.$store.dispatch("signIn", {
          email: this.email,
          password: this.password,
        });
        if (this.isAuthenticated) {
          if (this.$route.query && this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            this.$router.push("/ledger");
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.auth-input {
  padding-top: 5px;
  padding-bottom: 8px;
}

select,
option,
input {
  outline: 0;
  border: 0;
  background: transparent;
  border-bottom: 1px solid var(--black);
  max-width: 500px;
  margin: auto;
  display: block;
  width: 100%;
  font: inherit;
}

select,
input::placeholder {
  padding-left: 8px;
  color: var(--black);
}

select:focus,
option:focus,
input:focus {
  background-color: var(--sand);
  outline: none;
}

.is-invalid {
  border-color: var(--text-invalid);
  background-color: var(--focus-invalid);
}

.invalid-feedback {
  display: block;
  color: var(--text-invalid);
}
</style>
