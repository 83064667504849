<template>
  <Transition name="loader">
    <div class="ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </Transition>
</template>

<style scoped>
.loader-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.loader-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.loader-enter-active {
  transition: all 1s ease-out;
}

.loader-leave-active {
  transition: all 1s ease-in;
}

.loader-enter-to,
.loader-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.ellipsis {
  position: absolute;
  top: calc(50% - 46px);
  left: calc(50% - 26px);
  transform: translate(-50%, -50%);
}
.ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--brown);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ellipsis div:nth-child(1) {
  left: 8px;
  animation: ellipsis1 0.6s infinite;
}
.ellipsis div:nth-child(2) {
  left: 8px;
  animation: ellipsis2 0.6s infinite;
}
.ellipsis div:nth-child(3) {
  left: 32px;
  animation: ellipsis2 0.6s infinite;
}
.ellipsis div:nth-child(4) {
  left: 56px;
  animation: ellipsis3 0.6s infinite;
}
@keyframes ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
