export default {
  expenses(state) {
    return state.expenses;
  },
  recurringExpenses(state) {
    return state.recurringExpenses;
  },
  lastExpenses(state) {
    return state.lastExpensesForCategory;
  },
};
