<template>
  <button v-if="!link" :class="mode">
    <slot></slot>
  </button>
  <router-link v-else :to="to" :class="mode">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: null,
    },
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: "/",
    },
  },
};
</script>

<style scoped>
a,
button {
  margin-top: 5px;
  margin-right: 0.5rem;
  padding: 0.75rem 1rem;
  text-decoration: none;
  font: inherit;
  color: white;
  background-color: var(--green);
  border: none;
  border-radius: var(--radius);
  display: inline-block;
  cursor: pointer;
}

a:hover:focus:active,
button:hover:focus:active {
  background-color: var(--light-green);
}
</style>
