export default {
  setExpenses(state, expenses) {
    state.expenses = expenses;
  },
  setRecurringExpenses(state, recurringExpenses) {
    state.recurringExpenses = recurringExpenses;
  },
  setLastExpense(state, expenses) {
    state.lastExpensesForCategory = expenses;
  },
};
