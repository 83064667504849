<template>
  <div v-if="loaded">
    <div class="table-title">
      <h2>Profile Settings</h2>
    </div>
    <main class="page-content-wrapper">
      <div class="form-group row">
        <label for="userName" class="col-sm-2 col-form-label">Name:</label>
        <div class="col-sm-10">
          <input v-model="userName" class="form-control w-75" id="userName" />
        </div>
      </div>
      <div class="form-group row">
        <label for="pass" class="col-sm-2 col-form-label">Password:</label>
        <div class="col">
          <input id="pass" class="form-control" value="********" readonly />
        </div>
        <div class="col sec-col">
          <router-link to="/profile/changePassword"
            >Change Password</router-link
          >
        </div>
      </div>
      <div class="form-group row">
        <label for="email" class="col-sm-2 col-form-label">Email:</label>
        <div class="col-sm-10">
          <input id="email" class="form-control w-75" v-model="email" />
        </div>
      </div>
      <div class="form-group row">
        <label for="notif" class="col-sm-2 col-from-label"
          >Notifications:</label
        >
        <div class="col-sm-1">
          <b-form-radio-group
            class="form-check-inline"
            id="notif"
            v-model="notificationMethod"
            :options="notificationMethods"
          />
        </div>
      </div>
      <hr />
      <div class="form-group row">
        <label for="accountName" class="col-sm-2 col-form-label"
          >Account:</label
        >
        <div class="col-sm-10">
          <input
            v-model="accountName"
            class="form-control w-75"
            id="accountName"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="mems" class="col-sm-2 col-form-label">Members:</label>
        <div class="col">
          <input id="mems" class="form-control" :value="memberNames" readonly />
        </div>
        <div class="col sec-col">
          <router-link to="/profile/members">Manage</router-link>
        </div>
      </div>
      <div class="form-group row">
        <label for="desc" class="col-sm-2 col-form-label">Description:</label>
        <div class="col-sm-10">
          <b-form-textarea
            id="desc"
            class="form-control w-75"
            v-model="description"
            placeholder="Account Description..."
            rows="2"
            max-rows="4"
            no-resize
          />
        </div>
      </div>
    </main>
    <page-buttons
      v-show="hasMadeChanges"
      rightButtonName="Save Update"
      @rbClick="updateUserAndAccount"
    />
  </div>
  <page-loading v-else />
</template>

<script>
import { BFormRadioGroup, BFormTextarea } from "bootstrap-vue-3";
import { mapGetters } from "vuex";

export default {
  components: {
    BFormRadioGroup,
    BFormTextarea,
  },
  async created() {
    this.loaded = false;
    await Promise.all([
      this.$store.dispatch("loadSessionDetails"),
      this.$store.dispatch("loadAccountUsers"),
    ]);
    this.initValues();
  },
  data() {
    return {
      loaded: false,
      userName: "",
      email: "",
      notificationMethod: "email",
      accountName: "",
      description: "",
      notificationMethods: [
        { text: "Email", value: "email" },
        { text: "Text", value: "text", disabled: true },
        { text: "None", value: "none" },
      ],
    };
  },
  computed: {
    ...mapGetters(["account", "user", "memberNames"]),
    isValidUserForm() {
      var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return (
        this.userName !== null &&
        this.userName !== "" &&
        this.email !== null &&
        emailRegex.test(this.email)
      );
    },
    isValidAccountForm() {
      return this.accountName.trim() !== "";
    },
    userDataChanged() {
      if (
        this.email.trim() !== this.user.email ||
        this.userName.trim() !== this.user.user_name ||
        (this.notificationMethod == "email") !== this.user.allowsNotifications
      ) {
        return true;
      }
      return false;
    },
    accountDataChanged() {
      if (
        this.accountName.trim() !== this.account.account_name ||
        this.description.trim() !== this.account.description
      ) {
        return true;
      }
      return false;
    },
    hasMadeChanges() {
      return this.userDataChanged || this.accountDataChanged;
    },
  },
  methods: {
    initValues() {
      this.loaded = true;
      this.userName = this.user.user_name;
      this.email = this.user.email;
      this.notificationMethod = this.user.allowsNotifications
        ? "email"
        : "none";
      this.accountName = this.account.account_name;
      this.description =
        this.account.description !== null ? this.account.description : "";
    },
    updateUser() {
      if (this.isValidUserForm) {
        this.loaded = false;
        this.$store.dispatch("updateUser", {
          name: this.userName.trim(),
          email: this.email.trim(),
          allowsNotifications: this.notificationMethod == "email",
        });
      } else {
        this.$store.dispatch("notifyUser", {
          message: "You must have a name and valid email address",
          level: "danger",
        });
      }
    },
    updateAccount() {
      if (this.isValidAccountForm) {
        this.loaded = false;
        this.$store.dispatch("updateAccount", {
          accountName: this.accountName.trim(),
          description: this.description.trim(),
        });
      } else {
        this.$store.dispatch("notifyUser", {
          message: "An account name is required",
          level: "danger",
        });
      }
    },
    updateUserAndAccount() {
      if (this.userDataChanged) {
        this.updateUser();
      }
      if (this.accountDataChanged) {
        this.updateAccount();
      }
    },
  },
};
</script>

<style scoped>
.sec-col {
  justify-content: flex-start;
}
</style>

<style>
.clr-ovrd,
.form-control {
  border: 1px ridge var(--green);
  border-radius: var(--radius);
}
.clr-ovrd:focus,
.form-control:focus {
  border-color: var(--light-green);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px var(--brown);
}
</style>
