export default {
  setExpenseCategories(state, expenseCategories) {
    state.expenseCategories = expenseCategories;
  },
  setBudgetCategories(state, categories) {
    state.expenseCategories = categories;
  },
  setLastContributions(state, contributions) {
    state.lastBudgetContributions = contributions;
  },
};
