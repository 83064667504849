<template>
  <div v-if="loaded">
    <div class="table-title">
      <h2>
        {{ displayTitle }}
      </h2>
      <small>{{ accountName }}</small>
    </div>
    <main class="page-content-wrapper">
      <ul id="responsive-table">
        <li class="table-header">
          <div class="column column-1" v-show="showInvitor">Invitor</div>
          <div class="column column-2" v-show="showName">Name</div>
          <div class="column column-3">Email</div>
          <div class="column column-4" v-show="showJoined">Joined</div>
        </li>
        <li class="table-row" v-for="row in accountUsers" :key="row.user_id">
          <div class="column column-1" v-show="showInvitor">
            {{ row.invitor }}
          </div>
          <div class="column column-2" v-show="showName">
            {{ row.user_name }}
          </div>
          <div class="column column-3">{{ row.email }}</div>
          <div class="column column-4" v-show="showJoined">
            {{ monthYearFormat(row.created_date) }}
          </div>
        </li>
        <li class="table-row" v-show="showAddRow">
          <div class="column column-2">
            <fa-icon icon="trash" @click="closeAdd"></fa-icon>
          </div>
          <input
            ref="emailInput"
            class="row-input column column-3"
            type="text"
            v-model.trim="email"
            placeholder="Email"
          />
          <div class="column column-4" v-show="showJoined">
            {{ currentDate }}
          </div>
        </li>
      </ul>
    </main>
    <page-buttons :rightButtonName="buttonLabel" @rbClick="handleClick" />
  </div>
  <page-loading v-else />
</template>
<script>
import { mapGetters } from "vuex";
import { monthYearFormat } from "../../../scriptUtils";

export default {
  data() {
    return {
      loaded: false,
      showAddRow: false,
      email: "",
    };
  },
  computed: {
    ...mapGetters(["accountUsers", "accountName", "windowWidth"]),
    hasMadeChanges() {
      return this.email !== "";
    },
    currentDate() {
      const date = new Date();
      return date.toLocaleDateString("en-us", {
        month: "long",
        year: "numeric",
      });
    },
    buttonLabel() {
      if (this.showAddRow) {
        return "Send Invite";
      } else {
        return "Add Member";
      }
    },
    displayTitle() {
      return "Account Members";
    },
    showInvitor() {
      if (this.windowWidth > 650) {
        for (const user of this.accountUsers) {
          if (user.invitor) {
            return true;
          }
        }
      }
      return false;
    },
    showJoined() {
      return this.windowWidth > 550;
    },
    showName() {
      return this.windowWidth > 450;
    },
  },
  methods: {
    monthYearFormat,
    handleClick() {
      if (this.showAddRow) {
        this.save();
      } else {
        this.showAddRow = true;
        setTimeout(() => this.$refs.emailInput.focus(), 0);
      }
    },
    save() {
      if (this.hasMadeChanges) {
        this.loaded = false;
        this.$store.dispatch("addUser", {
          email: this.email,
        });
        this.closeAdd();
      } else {
        this.$store.dispatch("notifyUser", {
          message: "Email is required for invite",
          level: "warning",
        });
      }
    },
    closeAdd() {
      this.showAddRow = false;
      this.email = "";
      this.loaded = true;
    },
  },
  async created() {
    this.loaded = false;
    await Promise.all([
      this.$store.dispatch("loadSessionDetails"),
      this.$store.dispatch("loadAccountUsers"),
    ]);
    this.loaded = true;
  },
};
</script>
