<template>
  <nav class="sidebar">
    <div v-if="isAuthenticated">
      <side-bar-link to="/ledger" icon="book" :selected="isSelected('/ledger')"
        >View Ledger
      </side-bar-link>
      <side-bar-link
        to="/expense"
        icon="file-invoice"
        :selected="isSelected('/expense')"
        >Log an Expense
      </side-bar-link>
      <side-bar-link
        to="/profile/budgetCategories"
        icon="object-group"
        :selected="isSelected('/profile/budgetCategories')"
        >Budget Allocations
      </side-bar-link>
      <side-bar-link to="/income" icon="coins" :selected="isSelected('/income')"
        >Record a Payment
      </side-bar-link>
      <side-bar-link
        to="/budget"
        icon="money-bill"
        :selected="isSelected('/budget')"
        >Budget Funds
      </side-bar-link>
      <side-bar-link
        to="/recurring"
        icon="calendar-day"
        :selected="isSelected('/recurring')"
        >Manage Recurring
      </side-bar-link>
      <side-bar-link
        to="/recurring/upcoming"
        icon="calendar-check"
        :selected="isSelected('/recurring/upcoming')"
        >Upcoming
      </side-bar-link>
      <side-bar-link
        to="/profile/accountDetails"
        icon="clipboard-list"
        :selected="isSelected('/profile/accountDetails')"
      >
        Account Details
      </side-bar-link>
      <side-bar-link
        to="/profile/chargeOptions"
        icon="credit-card"
        :selected="isSelected('/profile/paymentMethods')"
        >Payment Methods
      </side-bar-link>
      <side-bar-link
        to="/profile/jobs"
        icon="briefcase"
        :selected="isSelected('/profile/jobs')"
        >Jobs
      </side-bar-link>
      <side-bar-link
        to="/profile/members"
        icon="users"
        :selected="isSelected('/profile/members')"
        >Members
      </side-bar-link>
      <side-bar-link
        class="bottom-nav-button"
        @click="logout()"
        to="/"
        icon="door-open"
      >
        Sign Out
      </side-bar-link>
    </div>
    <div v-else>
      <side-bar-link
        to="/whoami?method=signIn"
        icon="door-open"
        :selected="isSelected('/whoami?method=signIn')"
        >Sign In
      </side-bar-link>
      <side-bar-link
        to="/whoami?method=signUp"
        icon="list"
        :selected="isSelected('/whoami?method=signUp')"
        >Sign Up
      </side-bar-link>
      <side-bar-link
        to="/auth?method=forgotPassword"
        icon="toilet-paper"
        :selected="isSelected('/auth?method=forgotPassword')"
        >Forgot My Password
      </side-bar-link>
    </div>
  </nav>
</template>

<script>
import SideBarLink from "./SideBarLink.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    SideBarLink,
  },
  computed: {
    ...mapGetters(["isAuthenticated", "hasFundsToBudget"]),
    ...mapGetters("sideBarModule", ["collapsed"]),
    selectedProfileLink() {
      return this.$route.path.includes("/profile");
    },
  },
  data() {
    return {
      showProfileNav: false,
    };
  },
  watch: {
    collapsed(val) {
      this.showProfileNav = !val && this.selectedProfileLink;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    setProfileNav() {
      this.showProfileNav = !this.showProfileNav;
    },
    isSelected(path) {
      return this.$route.fullPath === path;
    },
  },
};
</script>

<style scoped>
.sidebar {
  color: var(--white);
  background-color: var(--blue);
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  width: 225px;
  margin-left: 50px;
}

.bottom-nav-button {
  flex-direction: column-reverse;
  position: absolute;
  bottom: 10px;
  width: 225px;
}

.hidden-nav {
  margin-left: 15px;
}
</style>
