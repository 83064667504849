<template>
  <div class="progress-wrapper">
    <div class="my-progress-bar">
      <span
        v-for="step in steps"
        :key="step"
        :class="{ dot: true, 'active-dot': step === currentStep }"
      ></span>
    </div>
    <h1 class="page-title">{{ pageTitle }}</h1>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Number,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    pageTitle: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
:root {
  --progressHeaderHeight: 150px;
}
</style>
<style scoped>
.progress-wrapper {
  position: absolute;
  width: 100%;
  height: var(--progressHeaderHeight);
  overflow: hidden;
}
.my-progress-bar {
  padding-top: 10px;
  text-align: center;
}
.dot {
  height: 19px;
  width: 19px;
  background-color: var(--light-green);
  border-radius: 50%;
  display: inline-block;
  margin: 15px;
  opacity: 75%;
}

.active-dot {
  height: 20px;
  width: 20px;
  background-color: var(--brown);
  opacity: 100%;
}
.page-title {
  font-size: 20px;
  line-height: 2.75;
  padding: 0;
  text-align: center;
  z-index: 1;
  position: static;
}
</style>
