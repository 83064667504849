import { createLogger, createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import authenticationModule from "./modules/authentication/index.js";
import budgetModule from "./modules/budget/index.js";
import earningModule from "./modules/earning/index.js";
import expenseModule from "./modules/expense/index.js";
import ledgerModule from "./modules/ledger/index.js";
import recurringModule from "./modules/recurring/index.js";
import sessionModule from "./modules/session/index.js";
import settingsModule from "./modules/settings/index.js";
import sideBarModule from "./modules/sidebar/index.js";
import staticModule from "./modules/statics/index.js";

export default createStore({
  state() {
    return {
      notification: {},
      windowWidth: 0,
    };
  },
  mutations: {
    setNotification(state, notif) {
      state.notification = notif;
    },
    setWindowWidth(state, width) {
      state.windowWidth = width;
    },
  },
  actions: {
    notifyUser(context, notif) {
      context.commit("setNotification", notif);
    },
    adjustWindowWidth(context, width) {
      context.commit("setWindowWidth", width);
    },
  },
  getters: {
    notification(state) {
      return state.notification;
    },
    windowWidth(state) {
      return state.windowWidth;
    },
  },
  modules: {
    authenticationModule,
    budgetModule,
    earningModule,
    expenseModule,
    ledgerModule,
    recurringModule,
    sessionModule,
    settingsModule,
    sideBarModule,
    staticModule,
  },
  plugins: [createPersistedState({ paths: ["staticState", "accountState"] })],
});
