export default {
  ledger(state) {
    return state.ledger;
  },
  expenseDetail(state) {
    return state.expenseDetail;
  },
  incomeDetail(state) {
    return state.incomeDetail;
  },
  storedFilterOptions(state) {
    return state.filterOptions;
  },
};
