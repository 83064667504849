<template>
  <div>
    <div class="table-title">
      <h2>Upcoming Details</h2>
    </div>
    <main class="page-content-wrapper">
      <div class="form-group row">
        <label for="date" class="col-sm-2 col-form-label">Expected on:</label>
        <div class="col-sm-10">
          <input
            id="date"
            class="form-control"
            :value="monthDayYearFormat(date)"
            readonly
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="amount" class="col-sm-2 col-form-label">Amount:</label>
        <div class="col-sm-10">
          <input
            id="amount"
            class="form-control"
            :value="formatedAmount"
            readonly
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="ff" class="col-sm-2 col-form-label">Frequency: </label>
        <div class="col-sm-10">
          <input id="ff" class="form-control" :value="frequency" readonly />
        </div>
      </div>
      <div class="form-group row">
        <label for="category" class="col-sm-2 col-form-label">Category: </label>
        <div class="col-sm-10">
          <input
            id="category"
            class="form-control"
            :value="category"
            readonly
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="details" class="col-sm-2 col-form-label">Notes:</label>
        <div class="col-sm-10">
          <input id="details" class="form-control" :value="details" readonly />
        </div>
      </div>
    </main>
    <page-buttons leftButtonName="Close" @lbClick="$emit('done')" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { monthDayYearFormat } from "../../scriptUtils";

export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
    amount: {
      required: true,
      type: Number,
    },
    frequency: {
      required: true,
      type: String,
    },
    category: {
      required: true,
      type: String,
    },
    details: {
      required: false,
      type: String,
    },
    date: {
      required: true,
      type: String,
    },
  },
  emits: ["done"],
  computed: {
    ...mapGetters(["currency"]),
    floatValue() {
      if (isNaN(this.amount)) {
        return 0.0;
      } else {
        return parseFloat(this.amount).toFixed(2);
      }
    },
    isNegative() {
      return this.floatValue < 0;
    },
    formatedAmount() {
      if (this.isNegative) {
        return this.currency + "(" + (-1 * this.floatValue).toFixed(2) + ")";
      } else {
        return this.currency + this.floatValue;
      }
    },
  },
  methods: {
    monthDayYearFormat,
    closeItem() {
      this.$emit("done");
    },
  },
};
</script>

<style scoped>
.page-content-wrapper {
  top: 80px;
}
</style>
