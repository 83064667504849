import { createRouter, createWebHistory } from "vue-router";
import AddExpense from "../views/protected/AddExpense.vue";
import AddIncome from "../views/protected/AddIncome.vue";
import AuthenticateAccount from "../views/AuthenticateAccount.vue";
import LedgerList from "../views/protected/LedgerList.vue";
import NotFound from "../views/NotFound.vue";
import RecurringExpenses from "../views/protected/RecurringExpenses.vue";
import RecurringEarnings from "../views/protected/RecurringEarnings.vue";
import AccountDetails from "../views/protected/profile/AccountDetails.vue";
import BudgetCategories from "../views/protected/profile/BudgetCategories.vue";
import IncomeSources from "../views/protected/profile/IncomeSources.vue";
import ChargeOptions from "../views/protected/profile/ChargeOptions.vue";
import AccountMembers from "../views/protected/profile/AccountMembers.vue";
import ChangePassword from "../views/protected/profile/ChangePassword.vue";
import BudgetFunds from "../views/protected/BudgetFunds.vue";
import UpcomingRecurring from "../views/protected/UpcomingRecurring.vue";
import store from "../store/index.js";

const routes = [
  {
    path: "/",
    alias: "/home",
    //don't want to render this, but
    //vue seems to requires component annotation to run beforeEnter script
    component: AuthenticateAccount,
    beforeEnter: (_to, _from, next) => {
      if (!store.getters.isAuthenticated) {
        next("/whoami");
      }
    },
    meta: {
      requiresUnauth: true,
      title: "Home",
    },
  },
  {
    path: "/whoami",
    name: "Account Authentication",
    component: AuthenticateAccount,
    alias: "/auth",
    props: (route) => ({
      method: "signIn",
      ...route.query,
    }),
    meta: {
      requiresUnAuth: true,
      title: "Account Authentication",
    },
  },
  {
    path: "/ledger",
    name: "My Ledger",
    component: LedgerList,
    meta: {
      requiresAuth: true,
      title: "My ledger",
    },
  },
  {
    path: "/budget",
    name: "Budget Funds",
    component: BudgetFunds,
    meta: {
      requiresAuth: true,
      title: "Budget Funds",
    },
  },
  {
    path: "/expense",
    name: "Log an Expense",
    component: AddExpense,
    meta: {
      requiresAuth: true,
      title: "Log an Expense",
    },
  },
  {
    path: "/income",
    alias: "/earning",
    name: "Log an Income",
    component: AddIncome,
    meta: {
      requiresAuth: true,
      title: "Log an Income",
    },
  },
  {
    path: "/recurring/expenses",
    alias: "/recurring",
    name: "Recurring Expenses",
    component: RecurringExpenses,
    meta: {
      requiresAuth: true,
      title: "Manage Recurring Expenses",
    },
  },
  {
    path: "/recurring/earnings",
    alias: "/recurring/incomes",
    name: "Recurring Earnings",
    component: RecurringEarnings,
    meta: {
      requiresAuth: true,
      title: "Manage Recurring Earnings",
    },
  },
  {
    path: "/recurring/upcoming",
    name: "Upcoming Recurring",
    component: UpcomingRecurring,
    meta: {
      requiresAuth: true,
      title: "Upcoming Recurring",
    },
  },
  {
    path: "/profile/accountDetails",
    alias: "/profile",
    name: "Profile",
    component: AccountDetails,
    meta: {
      requiresAuth: true,
      title: "Account Details",
    },
  },
  {
    path: "/profile/budgetCategories",
    alias: "/profile/expenseCategories",
    name: "Budget Categories",
    component: BudgetCategories,
    meta: {
      requiresAuth: true,
      title: "Budget Categories",
    },
  },
  {
    path: "/profile/jobs",
    name: "Income Sources",
    component: IncomeSources,
    meta: {
      requiresAuth: true,
      title: "Jobs",
    },
  },
  {
    path: "/profile/paymentMethods",
    alias: "/profile/chargeOptions",
    name: "Payment Methods",
    component: ChargeOptions,
    meta: {
      requiresAuth: true,
      title: "Payment Methods",
    },
  },
  {
    path: "/profile/members",
    name: "Account Members",
    component: AccountMembers,
    meta: {
      requiresAuth: true,
      title: "Account Members",
    },
    beforeEnter: (_to, _from, next) => {
      if (store.getters.isAuthenticated) {
        next();
      } else if (!store.getters.isAuthenticated) {
        next("/whoami");
      } else {
        next("/profile/accountDetails");
      }
    },
  },
  {
    path: "/profile/changePassword",
    name: "Change Password",
    component: ChangePassword,
    meta: {
      title: "Change Password",
    },
    beforeEnter: (to, _from, next) => {
      if (
        store.getters.isAuthenticated ||
        store.getters.fpt !== "" ||
        typeof to.query.fpt !== "undefined"
      ) {
        next();
      } else if (!store.getters.isAuthenticated) {
        next("/whoami");
      } else {
        next("/profile");
      }
    },
  },
  { path: "/:notFound(.*)", name: "Page not found", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _from, next) => {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    store.dispatch("notifyUser", {
      message: "Looks like you need to log in",
      level: "info",
    });
    next("/whoami");
  } else if (to.meta.requiresUnAuth && store.getters.isAuthenticated) {
    store.dispatch("notifyUser", {
      message: "No need to log in!",
      level: "info",
    });
    next("/ledger");
  } else {
    next();
  }
});

export default router;
