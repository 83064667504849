import axios from "axios";
import {
  headers,
  handleError,
  prepareLedgerFilter,
} from "../../utils/axiosUtil";

export default {
  loadLedgerHistory({ commit, rootGetters }, payload) {
    return axios
      .post(
        "/accounts/" + rootGetters.accountId + "/ledger",
        prepareLedgerFilter(payload),
        headers()
      )
      .then((res) => {
        commit("setLedger", res.data);
      })
      .catch((err) => {
        if (
          err.response != null &&
          err.response.status != null &&
          err.response.status == 404
        ) {
          commit("setLedger", {});
        }
        handleError(err);
      });
  },
  fetchExpenseDetails({ commit, rootGetters }, payload) {
    return axios
      .get(
        "/accounts/" + rootGetters.accountId + "/expenses/" + payload,
        headers()
      )
      .then((res) => {
        commit("setExpenseDetail", res.data);
      })
      .catch((err) => handleError(err));
  },
  fetchIncomeDetails({ commit, rootGetters }, payload) {
    return axios
      .get(
        "/accounts/" + rootGetters.accountId + "/earnings/" + payload,
        headers()
      )
      .then((res) => {
        commit("setIncomeDetail", res.data);
      })
      .catch((err) => handleError(err));
  },
};
