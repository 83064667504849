<template>
  <transition name="fade">
    <div v-if="isNavLink">
      <router-link
        :to="to"
        class="nav-link"
        @click="close"
        :key="$route.fullPath"
        :class="{ active: selected }"
      >
        <div>
          <fa-icon class="icon" :icon="icon"></fa-icon>
          <slot />
        </div>
      </router-link>
    </div>
    <div v-else>
      <div class="nav-link">
        <!-- :class="{ active: selected }" -->
        <div>
          <fa-icon class="icon" :icon="icon"></fa-icon>
          <slot />
        </div>
        <fa-icon v-show="!selected" icon="caret-down"></fa-icon>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: false,
      default: "",
    },
    icon: {
      type: String,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isNavLink() {
      return this.to !== "";
    },
  },
  methods: {
    close() {
      this.$store.dispatch("sideBarModule/toggle");
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.nav-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-weight: 400;
  user-select: none;
  margin: 0.1em 0;
  padding: 0.4em;
  border-radius: 0.25em;
  height: 1.5em;
  color: var(--white);
  text-decoration: none;
  justify-content: space-between;
  box-sizing: unset;
}
.nav-link:hover {
  background-color: var(--green);
}
.nav-link.active {
  border: solid 3px;
  border-color: var(--sand);
}
.nav-link .icon {
  flex-shrink: 0;
  width: 25px;
  margin-right: 10px;
}
</style>
