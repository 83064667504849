<template>
  <div v-if="loaded">
    <div class="table-title">
      <h2>Expense Details</h2>
    </div>
    <main class="page-content-wrapper">
      <div class="form-group row">
        <label for="cost" class="col-sm-2 col-form-label">Cost:</label>
        <div class="col-sm-10">
          <currency-input :useBootstrap="true" id="cost" v-model="cost" />
        </div>
      </div>
      <div class="form-group row">
        <label
          for="date"
          class="col-sm-2 col-form-label"
          v-if="expenseDetail.recurring"
          >Start Date:</label
        >
        <label for="date" class="col-sm-2 col-form-label" v-else>Date:</label>
        <div class="col-sm-10">
          <date-picker :useBootstrap="true" v-model="dateExpended" />
        </div>
      </div>
      <div class="form-group row" v-if="expenseDetail.recurring">
        <label for="ff" class="col-sm-2 col-form-label">Frequency: </label>
        <div class="col-sm-10">
          <b-form-select
            class="form-control"
            id="ff"
            v-model="ff_id"
            :options="feeFrequencies"
            value-field="id"
            text-field="name"
          >
          </b-form-select>
        </div>
      </div>
      <div class="form-group row">
        <label for="budgetCategory" class="col-sm-2 col-form-label"
          >Budget Category:
        </label>
        <div class="col-sm-10">
          <b-form-select
            class="form-control"
            id="budgetCategory"
            v-model="categoryId"
            :options="expenseCategories"
            value-field="id"
            text-field="name"
          >
          </b-form-select>
        </div>
      </div>
      <div class="form-group row">
        <label for="charge" class="col-sm-2 col-form-label">Paid With: </label>
        <div class="col-sm-10">
          <b-form-select
            class="form-control"
            id="charge"
            v-model="chargeOptionId"
            :options="chargeOptions"
            value-field="id"
            text-field="name"
          >
          </b-form-select>
        </div>
      </div>
      <div class="form-group row">
        <label for="options" class="col-sm-2 col-form-label"></label>
        <div class="col-sm-10">
          <b-form-checkbox-group
            id="options"
            :options="checkBoxOptions"
            v-model="checkBoxSelected"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="loc" class="col-sm-2 col-form-label">Location:</label>
        <div class="col-sm-10">
          <b-form-textarea
            id="loc"
            class="form-control"
            v-model="location"
            placeholder="e.g. Walmart"
            rows="1"
            max-rows="2"
            no-resize
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="notes" class="col-sm-2 col-form-label">Notes:</label>
        <div class="col-sm-10">
          <b-form-textarea
            id="notes"
            class="form-control"
            v-model="notes"
            placeholder="Optional..."
            rows="3"
            max-rows="4"
            no-resize
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="user" class="col-sm-2 col-form-label">Logged by:</label>
        <div class="col-sm-10">
          <input id="user" class="form-control" :value="enteredBy" readonly />
        </div>
      </div>
      <div class="form-group row">
        <label for="created" class="col-sm-2 col-form-label">Entered on:</label>
        <div class="col-sm-10">
          <input
            id="created"
            class="form-control"
            :value="monthDayYearFormat(expenseDetail.created_date)"
            readonly
          />
        </div>
      </div>
      <div class="form-group row" v-show="isUpdateDateDifferentFromCreatedDate">
        <label for="updated" class="col-sm-2 col-form-label"
          >Modified on:</label
        >
        <div class="col-sm-10">
          <input
            id="updated"
            class="form-control"
            :value="monthDayYearFormat(expenseDetail.updated_date)"
            readonly
          />
        </div>
      </div>
    </main>
    <page-buttons
      v-if="hasMadeChanges"
      rightButtonName="Update"
      leftButtonName="Close"
      @lbClick="$emit('done')"
      @rbClick="updateItem"
    />
    <page-buttons
      v-else
      rightButtonName="Delete"
      leftButtonName="Close"
      @lbClick="$emit('done')"
      @rbClick="deleteItem"
    />
  </div>
  <page-loading v-else />
</template>

<script>
import { mapGetters } from "vuex";
import { monthDayYearFormat } from "../../scriptUtils";
import DatePicker from "../base_ui/DatePicker.vue";
import {
  BFormSelect,
  BFormCheckboxGroup,
  BFormTextarea,
} from "bootstrap-vue-3";
import CurrencyInput from "../base_ui/CurrencyInput.vue";

export default {
  components: {
    CurrencyInput,
    DatePicker,
    BFormSelect,
    BFormCheckboxGroup,
    BFormTextarea,
  },
  props: {
    expenseId: {
      required: true,
      type: String,
    },
  },
  emits: ["done"],
  data() {
    return {
      loaded: false,
      cost: 0,
      dateExpended: new Date().toLocaleString("sv-SE"),
      categoryId: "",
      chargeOptionId: "",
      location: "",
      notes: "",
      ff_id: "",
      checkBoxSelected: [],
      checkBoxOptions: [
        { text: "Tax Deductable", value: "tax" },
        { text: "Online Purchase", value: "online" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "expenseDetail",
      "expenseCategories",
      "chargeOptions",
      "feeFrequencies",
    ]),
    hasMadeChanges() {
      return (
        this.cost !== parseFloat(this.expenseDetail.cost) ||
        this.dateExpended !== this.expenseDetail.date_expended ||
        this.onlinePurchase !== this.expenseDetail.online_purchase ||
        this.taxDeductable !== this.expenseDetail.tax_deductable ||
        this.location !== this.expenseDetail.location ||
        this.notes !== this.expenseDetail.notes ||
        this.categoryId !== this.expenseDetail.ec_id ||
        this.chargeOptionId !== this.expenseDetail.co_id ||
        this.ff_id !== this.expenseDetail.ff_id
      );
    },
    enteredBy() {
      if (this.expenseDetail.recurring_fk) {
        return "System Automated Recurring Entry";
      } else {
        return this.expenseDetail.user_name;
      }
    },
    isUpdateDateDifferentFromCreatedDate() {
      return (
        this.expenseDetail.created_date !== this.expenseDetail.updated_date
      );
    },
    taxDeductable() {
      return this.checkBoxSelected.includes("tax");
    },
    onlinePurchase() {
      return this.checkBoxSelected.includes("online");
    },
    validForm() {
      return this.cost > 0;
    },
  },
  methods: {
    monthDayYearFormat,
    deleteItem() {
      if (
        confirm(
          "Are you sure you want to delete this Expense?\n This action cannot be undone."
        )
      ) {
        this.loaded = false;
        this.$store.dispatch("deleteExpense", {
          id: this.expenseId,
          recurring: this.expenseDetail.recurring,
        });
        this.$emit("done");
      }
    },
    updateItem() {
      if (this.validForm) {
        this.loaded = false;
        this.$store.dispatch("updateExpense", {
          expenseId: this.expenseId,
          ec_id: this.categoryId,
          co_id: this.chargeOptionId,
          cost: this.cost,
          date: this.dateExpended,
          online: this.onlinePurchase,
          taxDeductable: this.taxDeductable,
          location: this.location,
          notes: this.notes,
          ff_id: this.ff_id,
          recurring: this.expenseDetail.recurring,
        });
        this.$emit("done");
      } else {
        this.$store.dispatch("notifyUser", {
          message: "Cost most not be greater than $0",
          level: "danger",
        });
      }
    },
    closeItem() {
      if (
        !this.hasMadeChanges ||
        confirm(
          "You've edited this item. Are you sure you want to exit? Closing will not save your changes."
        )
      ) {
        this.$emit("done");
      }
    },
    initExpenseData() {
      this.cost = parseFloat(this.expenseDetail.cost);
      this.dateExpended = this.expenseDetail.date_expended;
      if (this.expenseDetail.online_purchase) {
        this.checkBoxSelected.push("online");
      }
      if (this.expenseDetail.tax_deductable) {
        this.checkBoxSelected.push("tax");
      }
      this.location = this.expenseDetail.location;
      this.notes = this.expenseDetail.notes;
      this.categoryId = this.expenseDetail.ec_id;
      this.chargeOptionId = this.expenseDetail.co_id;
      this.ff_id = this.expenseDetail.ff_id;
    },
  },
  async created() {
    this.loaded = false;
    await Promise.all([
      this.$store.dispatch("loadFeeFrequencies"),
      this.$store.dispatch("loadExpenseCategories"),
      this.$store.dispatch("loadChargeOptions"),
      this.$store.dispatch("fetchExpenseDetails", this.expenseId),
    ]);
    this.loaded = true;
    this.initExpenseData();
  },
};
</script>

<style scoped>
.page-content-wrapper {
  top: 80px;
}
</style>
