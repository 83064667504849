<template>
  <footer>
    <div v-if="useLeftButton && useRightButton" class="page-bottom">
      <button @click="leftClick" class="bottom-button">{{ lbName }}</button>
      <button @click="rightClick" class="bottom-button">{{ rbName }}</button>
    </div>
    <div
      v-else-if="useRightButton"
      :style="'{justify-content:flex-end}'"
      class="page-bottom-right"
    >
      <button @click="rightClick" class="bottom-button">{{ rbName }}</button>
    </div>
    <div v-else class="page-bottom-left">
      <button @click="leftClick" class="bottom-button">{{ lbName }}</button>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    steps: {
      type: Number,
      required: false,
      default: null,
    },
    currentStep: {
      type: Number,
      required: false,
      default: null,
    },
    leftButtonName: {
      type: String,
      required: false,
      default: null,
    },
    rightButtonName: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    lbName() {
      if (
        this.leftButtonName !== null ||
        this.steps == null ||
        this.currentStep === null
      ) {
        return this.leftButtonName;
      }
      switch (this.currentStep) {
        case 1:
          return ""; //no button
        default:
          return "Back";
      }
    },
    rbName() {
      if (
        this.rightButtonName !== null ||
        this.steps === null ||
        this.currentStep === null
      ) {
        return this.rightButtonName;
      }
      switch (this.currentStep) {
        case this.steps: //last step
          return "Save";
        default:
          return "Next";
      }
    },
    useLeftButton() {
      return this.lbName !== "" && this.lbName !== null;
    },
    useRightButton() {
      return this.rbName !== "" && this.rbName !== null;
    },
  },
  emits: ["lbClick", "rbClick"],
  methods: {
    leftClick() {
      this.$emit("lbClick");
    },
    rightClick() {
      this.$emit("rbClick");
    },
  },
};
</script>

<style>
:root {
  --pageButtonHeight: 82px;
}
</style>

<style scoped>
.page-bottom-right,
.page-bottom-left,
.page-bottom {
  position: absolute;
  height: var(--pageButtonHeight);
  left: 0;
  bottom: 0;
  top: auto;
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
}

.page-bottom {
  justify-content: space-between;
}

.page-bottom-right {
  justify-content: flex-end;
}

.page-bottom-left {
  justify-content: flex-start;
}

.bottom-button {
  margin: 20px 30px;
  padding: 0.75rem 1rem;
  text-decoration: none;
  font: inherit;
  color: var(--white);
  background-color: var(--green);
  border: none;
  border-radius: var(--radius);
  cursor: pointer;
}
</style>
