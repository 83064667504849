import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCreditCard,
  faBars,
  faUser,
  faReceipt,
  faCoins,
  faDoorOpen,
  faList,
  faTrash,
  faBook,
  faBriefcase,
  faUsers,
  faFileInvoice,
  faObjectGroup,
  faCaretDown,
  faClipboardList,
  faCalendarDay,
  faToiletPaper,
  faMoneyBill,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import { faWindowClose } from "@fortawesome/free-regular-svg-icons";

export const icons = () => {
  library.add(
    faList,
    faCoins,
    faCreditCard,
    faDoorOpen,
    faBars,
    faUser,
    faReceipt,
    faWindowClose,
    faTrash,
    faBook,
    faBriefcase,
    faUsers,
    faFileInvoice,
    faObjectGroup,
    faCaretDown,
    faClipboardList,
    faCalendarDay,
    faToiletPaper,
    faMoneyBill,
    faCalendarCheck
  );
};
