<template>
  <section>
    <div>
      <h2>Forgot Password</h2>
      <h6>What's your account email?</h6>
    </div>
    <form @submit.prevent="submitForgotPassword" autocomplete="on">
      <div class="auth-input">
        <input
          type="email"
          id="email"
          placeholder="Email"
          v-model.trim="email"
          :class="{ 'is-invalid': v$.email.$error }"
          autocomplete="username"
          ref="email"
        />
        <div v-if="v$.email.$error" class="invalid-feedback">
          <span v-if="v$.email.required">*Email is Required</span>
          <span v-else-if="v$.email.email">*Email is not Valid</span>
        </div>
      </div>
      <base-button type="submit">Submit</base-button>
    </form>
  </section>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "vuelidate/lib/validators";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  mounted() {
    this.$refs.email.focus();
  },
  data() {
    return {
      email: "",
    };
  },
  emits: ["formSubmitted"],
  validations() {
    return {
      email: { required, email },
    };
  },
  methods: {
    submitForgotPassword() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        this.$emit("formSubmitted");
        this.$store.dispatch("forgotPassword", {
          email: this.email,
        });
        this.$store.dispatch("notifyUser", {
          message:
            "If an account with that email address exists, a link will be sent to reset the password",
          level: "dark",
        });
        this.$router.push("/");
        setTimeout(() => {
          this.$store.dispatch("notifyUser", {
            message: "Check your spam folder, if you don't find the email",
            level: "warning",
          });
        }, 1000);
      }
    },
  },
};
</script>

<style scoped>
.auth-input {
  padding-top: 5px;
  padding-bottom: 8px;
}

select,
option,
input {
  outline: 0;
  border: 0;
  background: transparent;
  border-bottom: 1px solid var(--black);
  max-width: 500px;
  margin: auto;
  display: block;
  width: 100%;
  font: inherit;
}

select,
input::placeholder {
  padding-left: 8px;
  color: var(--black);
}

select:focus,
option:focus,
input:focus {
  background-color: var(--sand);
  outline: none;
}

.is-invalid {
  border-color: var(--text-invalid);
  background-color: var(--focus-invalid);
}

.invalid-feedback {
  color: var(--text-invalid);
}
</style>
