<template>
  <section v-if="!loading">
    <div class="content">
      <main v-if="isSignUp()">
        <sign-up @form-submitted="loading = true"></sign-up>
        <div class="auth-nav-links">
          <p>
            Already have an account?
            <router-link to="/whoami?method=signIn">Sign In</router-link>
          </p>
        </div>
      </main>
      <main v-else-if="isForgotPassword()">
        <forgot-password></forgot-password>
        <div class="auth-nav-links">
          <p>
            Don't have an account?
            <router-link to="/whoami?method=signUp">Sign Up</router-link>
          </p>
        </div>
      </main>
      <main v-else>
        <sign-in @form-submitted="loading = true"></sign-in>
        <div class="auth-nav-links">
          <p>
            Forgot Password?
            <router-link to="/auth?method=forgotPassword">reset</router-link>
          </p>
          <p>
            Don't have an account?
            <router-link to="/whoami?method=signUp">Sign Up</router-link>
          </p>
        </div>
      </main>
    </div>
  </section>
  <page-loading v-else />
</template>

<script>
import SignUp from "../components/view_support/SignUp.vue";
import SignIn from "../components/view_support/SignIn.vue";
import ForgotPassword from "../components/view_support/ForgotPassword.vue";

export default {
  components: {
    SignUp,
    SignIn,
    ForgotPassword,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    isSignUp() {
      let method = this.$route.query.method;
      if (typeof method === "undefined") {
        return false;
      } else {
        const signingUp = method.toLowerCase() === "signup";
        if (signingUp) {
          let jat = this.$route.query.jat;
          if (typeof jat !== "undefined") {
            this.$router.push({
              path: "/auth",
              query: { method: "signup" },
            }); //make url prettier
            this.$store.dispatch("checkJat", jat);
          }
        }
        return signingUp;
      }
    },
    isForgotPassword() {
      let method = this.$route.query.method;
      if (typeof method === "undefined") {
        return false;
      } else {
        return method.toLowerCase() === "forgotpassword";
      }
    },
  },
};
</script>

<style scoped>
.content {
  background-color: var(--white-transparent);
  border-radius: var(--radius);
  border: solid 2px var(--green);
  box-shadow: 0 0 0.25em var(--shadow);
  box-sizing: border-box;
  left: 50%;
  padding: 7vmin;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 250px;
  min-height: 300px;
}
.auth-nav-links {
  margin-top: 25px;
  display: grid;
}
</style>
