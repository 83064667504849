<template>
  <form @submit.prevent>
    <input
      v-if="useBootstrap"
      class="form-control"
      ref="inputRef"
      type="text"
      inputmode="decimal"
      @focus="focusInputIn"
      @focusout="focusInputOut"
    />
    <input
      v-else-if="rightText"
      :style="'{text-align: right}'"
      class="form-control"
      type="text"
      ref="inputRef"
      inputmode="decimal"
      @focus="focusInputIn"
      @focusout="focusInputOut"
    />
    <input
      v-else
      class="currency-input"
      ref="inputRef"
      type="text"
      inputmode="decimal"
      @focus="focusInputIn"
      @focusout="focusInputOut"
    />
  </form>
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: {
    modelValue: Number,
    useBootstrap: {
      type: Boolean,
      default: false,
    },
    rightText: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { inputRef } = useCurrencyInput({
      currency: "USD",
      currencyDisplay: "symbol",
      accountingSign: true,
      autoDecimalDigits: true,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      useGrouping: true,
      valueRange: {
        min: !props.rightText && !props.useBootstrap ? 0 : -999999,
        max: 999999,
      },
    });

    return { inputRef };
  },
  mounted() {
    if (!this.rightText && !this.useBootstrap) {
      this.$refs.inputRef.focus();
    }
  },
  methods: {
    focusInputIn(evt) {
      let amount = this.getAmount(evt);
      if (parseFloat(amount) === 0) {
        evt.target.value = null;
      }
    },
    focusInputOut(evt) {
      let amount = this.getAmount(evt);
      if (isNaN(parseFloat(amount))) {
        evt.target.value = "$0.00";
      }
    },
    getAmount(evt) {
      return evt.target.value
        .replace("$", "")
        .replace("-", "")
        .replace("(", "")
        .replace(")", "");
    },
  },
};
</script>

<style scoped>
.currency-input {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  border: 2px solid var(--brown);
  border-radius: var(--radius);
  font-size: xx-large;
  width: 200px;
  text-align: center;
}
</style>
