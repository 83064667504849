<template>
  <div v-if="loaded">
    <table-list
      :titleSuffix="titleSuffix"
      :rows="chargeOptions"
      :resourceType="resourceType"
    />
  </div>
  <page-loading v-else />
</template>
<script>
import { mapGetters } from "vuex";
import TableList from "../../../components/view_support/TableList.vue";

export default {
  components: {
    TableList,
  },
  computed: {
    ...mapGetters(["chargeOptions"]),
  },
  data() {
    return {
      titleSuffix: "Payment Method",
      loaded: false,
      resourceType: "chargeOptions",
    };
  },
  async created() {
    this.loaded = false;
    await Promise.all([
      this.$store.dispatch("loadSessionDetails"),
      this.$store.dispatch("loadChargeOptions"),
    ]);
    this.loaded = true;
  },
};
</script>
