<template>
  <datepicker
    v-if="!useBootstrap"
    class="date-input"
    v-model="date"
    :clearable="false"
    :autoApply="true"
    :maxDate="new Date()"
    :enableTimePicker="false"
    :weekStart="0"
    :format="dateFormat"
  />
  <datepicker
    v-else
    v-model="date"
    :clearable="false"
    :autoApply="true"
    :maxDate="new Date()"
    :enableTimePicker="false"
    :weekStart="0"
    :format="dateFormat"
    class="clr-ovrd"
  />
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      date: new Date(),
    };
  },
  props: {
    useBootstrap: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    dateFormat(date) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const formater = new Date(date);
      return `${
        monthNames[formater.getMonth()]
      } ${formater.getDate()}, ${formater.getFullYear()}`;
    },
  },
  emits: ["selected"],
  watch: {
    date() {
      this.$emit("selected", this.date);
    },
  },
};
</script>

<style>
.dp__theme_light {
  --dp-hover-color: var(--sand);
  --dp-primary-color: var(--green);
  --dp-success-color: var(--blue);
}
</style>

<style scoped>
.date-input {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid var(--brown);
  border-radius: var(--radius);
}
</style>
