<template>
  <div v-if="loaded && setup">
    <progress-indicator
      :steps="totalSteps"
      :currentStep="parseInt(formStep)"
      :pageTitle="pageTitle"
    />
    <main class="form-content">
      <Transition name="form-fade">
        <div v-show="showStep(1)">
          <ul class="list-selection">
            <li
              :class="{ 'list-item': true, selected: activeSource(source.id) }"
              v-for="source in incomeSources"
              :key="source.id"
              @click="setSource(source.id)"
            >
              {{ source.name }}
            </li>
          </ul>
        </div>
      </Transition>
      <Transition name="form-fade">
        <div v-show="showStep(2)">
          <currency-input @submit="next" v-model="amount" />
        </div>
      </Transition>
      <Transition name="form-fade">
        <div v-show="showStep(3)">
          <div v-if="showFeeFrequencies">
            <ul class="list-selection">
              <li
                :class="{
                  'list-item': true,
                  selected: activeFeeFrequency(ff.id),
                }"
                v-for="ff in feeFrequencies"
                :key="ff.id"
                @click="setFeeFrequency(ff.id)"
              >
                {{ ff.name }}
              </li>
            </ul>
          </div>
          <div v-else>
            <date-picker v-model="date" />
          </div>
        </div>
      </Transition>
      <Transition>
        <div v-show="showStep(4)">
          <textarea class="note-input" v-model="notes" placeholder="notes...">
          </textarea>
        </div>
      </Transition>
    </main>
    <page-buttons
      :steps="parseInt(totalSteps)"
      :currentStep="parseInt(formStep)"
      @lbClick="prev"
      @rbClick="next"
    />
  </div>
  <div v-else-if="loaded && !setup" class="setup-message">
    <p>
      In order to record a payment, you first need to setup your
      <router-link to="/profile/jobs">Jobs</router-link>.
    </p>
  </div>
  <page-loading v-else />
</template>

<script>
import { mapGetters } from "vuex";
import ProgressIndicator from "../../components/base_ui/ProgressIndicator.vue";
import CurrencyInput from "../../components/base_ui/CurrencyInput.vue";
import DatePicker from "../../components/base_ui/DatePicker.vue";

export default {
  components: {
    DatePicker,
    CurrencyInput,
    ProgressIndicator,
  },
  async created() {
    this.loaded = false;
    await Promise.all([
      this.$store.dispatch("loadSessionDetails"),
      this.$store.dispatch("loadIncomeSources"),
      this.$store.dispatch("loadFeeFrequencies"),
    ]);
    this.loaded = true;
    this.reset();
  },
  computed: {
    ...mapGetters(["incomeSources", "feeFrequencies"]),
    validForm() {
      return this.incomeSourceId !== "" && this.amount !== 0;
    },
    pageTitle() {
      switch (this.formStep) {
        case 1:
          return "How did you get paid?";
        case 2:
          return "What's your earnings?";
        case 3:
          if (this.recurring) {
            return "How often is this income?";
          } else {
            return "When were you paid?";
          }
        case 4:
          return "Optional Income Details";
        default:
          return "";
      }
    },
    hasIncomeSources() {
      return this.incomeSources !== null && this.incomeSources.length > 0;
    },
    setup() {
      return this.hasIncomeSources;
    },
    showFeeFrequencies() {
      return this.recurring;
    },
  },
  data() {
    return {
      loaded: false,
      incomeSourceId: "",
      amount: 0,
      notes: "",
      date: new Date(),
      formStep: 1,
      totalSteps: 4,
      recurring: false,
      feeFrequencyId: -1,
    };
  },
  methods: {
    showStep(num) {
      return this.formStep === num;
    },
    setSource(id) {
      this.incomeSourceId = id;
      this.next();
    },
    activeSource(id) {
      return this.incomeSourceId === id;
    },
    setFeeFrequency(val) {
      this.feeFrequencyId = val;
      this.next();
    },
    activeFeeFrequency(val) {
      return this.feeFrequencyId == val;
    },
    next() {
      if (this.formStep === this.totalSteps) {
        this.save();
      } else if (this.showStep(1) && this.incomeSourceId === "") {
        this.$store.dispatch("notifyUser", {
          message: "Income Source is Required",
          level: "danger",
        });
      } else if (this.showStep(2) && this.amount === 0) {
        this.$store.dispatch("notifyUser", {
          message: "Income Amount is Required",
          level: "danger",
        });
      } else if (
        this.showStep(3) &&
        this.showFeeFrequencies &&
        this.feeFrequencyId === -1
      ) {
        this.$store.dispatch("notifyUser", {
          message: "Frequency is Required",
          level: "danger",
        });
      } else {
        this.formStep = this.formStep + 1;
      }
    },
    prev() {
      this.formStep = this.formStep - 1;
    },
    async save() {
      if (this.validForm) {
        this.loaded = false;
        await this.$store.dispatch("saveEarning", {
          is_id: this.incomeSourceId,
          profit: this.amount,
          payDay: this.date.toLocaleString("sv-SE"),
          notes: this.notes,
          recurringEarning: this.recurring,
          ff_id: this.feeFrequencyId,
        });
        this.close();
      }
    },
    reset() {
      this.amount = 0;
      this.notes = "";
      this.date = new Date();
      this.formStep = 1;
      if (this.$route.query && this.$route.query.recurring) {
        this.recurring = new Boolean(this.$route.query.recurring);
      } else {
        this.recurring = false;
      }
      this.feeFrequencyId = this.feeFrequencies[3].id;
      this.incomeSourceId = this.incomeSources[0].id;
    },
    close() {
      const recurringRedirect = this.recurring;
      this.reset();
      if (recurringRedirect) {
        this.$router.push("/recurring/earnings");
      } else {
        this.$router.push("/budget");
      }
    },
  },
};
</script>

<style scoped>
.form-content {
  position: absolute;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  top: var(--progressHeaderHeight);
  bottom: var(--pageButtonHeight);
}

.list-selection {
  list-style-type: none;
  margin: 0;
  border: 0;
  padding: 2px;
}
.list-item {
  background-color: var(--white);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  margin-left: 55px;
  margin-right: 55px;
}

.selected,
.list-item:hover,
.list-item:focus {
  border: 2px solid var(--light-green);
}

.note-input {
  display: flex;
  justify-content: center;
  margin: 60px auto;
  width: 70%;
  height: 120px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid var(--brown);
  border-radius: var(--radius);
  background-color: var(--white);
  font-size: 16px;
  resize: none;
}

.note-input:focus {
  outline: none;
  border-color: var(--light-green);
}

.setup-message {
  margin: 45px;
}

.switch-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  justify-content: center;
  align-items: center;
}

.switch-input {
  display: flex;
  padding: 12px 20px;
}
</style>
