import axios from "axios";
import { headers, handleError } from "../../utils/axiosUtil";

export default {
  saveEarning({ rootGetters, dispatch }, payload) {
    return axios
      .post(
        "/accounts/" + rootGetters.accountId + "/earnings",
        payload,
        headers()
      )
      .then(() => {
        dispatch("notifyUser", {
          message: "Earning Saved",
          level: "success",
        });
        if (payload.recurringEarning) {
          dispatch("loadRecurringEarnings");
        } else {
          dispatch("loadLedgerHistory", rootGetters.storedFilterOptions);
        }
      })
      .catch((err) => handleError(err));
  },
  loadRecurringEarnings({ commit, rootGetters }) {
    return axios
      .get(
        "/accounts/" +
          rootGetters.accountId +
          "/earnings?findNonRecurring=false",
        headers()
      )
      .then((res) => {
        commit("setRecurringEarnings", res.data.recurringEarnings);
      })
      .catch((err) => handleError(err));
  },
  updateEarning({ rootGetters, dispatch }, payload) {
    return axios
      .put(
        "/accounts/" + rootGetters.accountId + "/earnings/" + payload.earningId,
        payload,
        headers()
      )
      .then(() => {
        dispatch("notifyUser", {
          message: "Earning updated",
          level: "success",
        });
        if (payload.recurring) {
          dispatch("loadRecurringEarnings");
        } else {
          dispatch("loadLedgerHistory", rootGetters.storedFilterOptions);
        }
      })
      .catch((err) => handleError(err));
  },
  deleteEarning({ rootGetters, dispatch }, detail) {
    return axios
      .delete(
        "/accounts/" + rootGetters.accountId + "/earnings/" + detail.id,
        headers()
      )
      .then(() => {
        dispatch("notifyUser", {
          message: "Earning deleted",
          level: "dark",
        });
        if (detail.recurring) {
          dispatch("loadRecurringEarnings");
        } else {
          dispatch("loadLedgerHistory", rootGetters.storedFilterOptions);
        }
      })
      .catch((err) => handleError(err));
  },
};
