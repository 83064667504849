<template>
  <span v-if="!colored">
    {{ formatedAmount }}
  </span>
  <span v-else-if="isNegative" class="negative-amount">
    {{ formatedAmount }}
  </span>
  <span v-else class="positive-amount">
    {{ formatedAmount }}
  </span>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    amount: {
      required: true,
    },
    colored: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(["currency"]),
    floatValue() {
      if (isNaN(this.amount)) {
        return 0.0;
      } else {
        return parseFloat(this.amount).toFixed(2);
      }
    },
    isNegative() {
      return this.floatValue < 0;
    },
    formatedAmount() {
      if (this.isNegative) {
        return this.currency + "(" + (-1 * this.floatValue).toFixed(2) + ")";
      } else {
        return this.currency + this.floatValue;
      }
    },
  },
};
</script>
<style scoped>
.negative-amount {
  color: var(--red);
}
.positive-amount {
  color: var(--green);
}
</style>
