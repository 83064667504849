import axios from "axios";
import { headers, handleError } from "../../utils/axiosUtil";

export default {
  loadCurrencies({ commit }) {
    return axios
      .get("/statics/currencies", headers())
      .then((res) => {
        commit("setCurrencies", res.data);
      })
      .catch((err) => handleError(err, commit));
  },
  loadFeeFrequencies({ commit }) {
    return axios
      .get("/statics/feeFrequencies", headers())
      .then((res) => {
        commit("setFeeFrequencies", res.data);
      })
      .catch((err) => handleError(err, commit));
  },
  loadBudgetQuote({ commit }) {
    return axios
      .get("/statics/budgetQuote", headers())
      .then((res) => {
        commit("setBudgetQuote", res.data);
      })
      .catch((err) => handleError(err, commit));
  },
};
