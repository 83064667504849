import { createApp, h } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BaseButton from "./components/base_ui/BaseButton.vue";
import PageLoading from "./components/base_ui/PageLoading.vue";
import ToggleSwitch from "./components/base_ui/ToggleSwitch.vue";
import PageButtons from "./components/layout/PageButtons.vue";
import { icons } from "./assets/fontLibrary.js";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const app = createApp({
  render: () => h(App),
});

app.use(icons);
app.use(store);
app.use(router);

app.component("fa-icon", FontAwesomeIcon);
app.component("page-loading", PageLoading);
app.component("base-button", BaseButton);
app.component("toggle-switch", ToggleSwitch);
app.component("page-buttons", PageButtons);

app.mount("#app");
