<template>
  <div v-if="loaded">
    <div class="table-title">
      <h2>My Ledger</h2>
      <div v-if="hasLedgerData">
        Net total:
        <accounting-formated :amount="ledger.total" />
      </div>
    </div>
    <main class="page-content-wrapper" v-if="hasLedgerData">
      <ul
        id="responsive-table"
        v-for="dateItem in ledger.ledger"
        :key="dateItem.date"
      >
        <li class="table-header">
          <div class="column column-1">
            {{ weekdayMonthDayFormat(dateItem.date) }}
          </div>
          <div class="column column-2">
            <accounting-formated
              :amount="dateItem.datetotal"
              :colored="false"
            />
          </div>
        </li>
        <li
          class="table-row"
          v-for="history in dateItem.history"
          :key="history.id"
          @click="showElement(history.type, history.id)"
        >
          <div class="column column-1">
            {{ history.category }}
            <div class="user" v-if="history.recurring_fk">Recurring Entry</div>
            <div class="user" v-else-if="isDifferentUser(history.user_id)">
              {{ history.user_name }}
            </div>
          </div>
          <div class="column column-2">
            <accounting-formated :amount="history.amount" />
          </div>
        </li>
      </ul>
    </main>
    <section class="page-content-wrapper" v-else>
      <p>
        Adjust your filter settings, or get started by
        <router-link to="/expense">Logging an Expense</router-link> or
        <router-link to="/income">Recording a Payment</router-link>
      </p>
    </section>
    <pop-up
      v-if="showFilterOptions"
      @close="toggleFilter"
      #default="{ closed }"
    >
      <ledger-filter @done="closed" />
    </pop-up>
    <pop-up
      v-if="showExpenseDetails"
      @close="closeDetailView"
      #default="{ closed }"
    >
      <expense-details :expenseId="detailId" @done="closed" />
    </pop-up>
    <pop-up
      v-if="showIncomeDetails"
      @close="closeDetailView"
      #default="{ closed }"
    >
      <earning-details :earningId="detailId" @done="closed" />
    </pop-up>
    <page-buttons rightButtonName="Filter" @rbClick="toggleFilter" />
  </div>
  <page-loading v-else />
</template>
<script>
import { mapGetters } from "vuex";
import {
  weekdayMonthDayFormatWithTimezoneOffset,
  monthDayFormat,
} from "../../scriptUtils";
import PopUp from "../../components/pop_up/PopUp.vue";
import LedgerFilter from "../../components/pop_up/LedgerFilter.vue";
import ExpenseDetails from "../../components/pop_up/ExpenseDetails.vue";
import EarningDetails from "../../components/pop_up/EaringDetails.vue";
import AccountingFormated from "../../components/base_ui/AccountingFormated.vue";

export default {
  components: {
    PopUp,
    LedgerFilter,
    ExpenseDetails,
    EarningDetails,
    AccountingFormated,
  },
  data() {
    return {
      loaded: false,
      showFilterOptions: false,
      showExpenseDetails: false,
      showIncomeDetails: false,
      detailId: "",
    };
  },
  computed: {
    ...mapGetters(["ledger", "storedFilterOptions", "userId"]),
    hasLedgerData() {
      return Object.keys(this.ledger).length > 0;
    },
    formatedDateRange() {
      if (this.storedFilterOptions.dateRange.length == 0) {
        return "";
      }
      return (
        " " +
        monthDayFormat(this.storedFilterOptions.dateRange[0]) +
        " - " +
        monthDayFormat(this.storedFilterOptions.dateRange[1])
      );
    },
  },
  async created() {
    this.loaded = false;
    await this.$store.dispatch("loadSessionDetails"); //this call must execute first
    await Promise.all([
      this.$store.dispatch(
        "loadLedgerHistory",
        this.$store.getters.storedFilterOptions
      ),
    ]);
    this.loaded = true;
  },
  methods: {
    weekdayMonthDayFormat: weekdayMonthDayFormatWithTimezoneOffset,
    toggleFilter() {
      this.showFilterOptions = !this.showFilterOptions;
    },
    isExpense(type) {
      return type === "expense";
    },
    isDifferentUser(id) {
      return this.userId !== id;
    },
    showElement(elementType, historyId) {
      this.closeDetailView();
      this.detailId = historyId;
      if (elementType === "income") {
        this.showIncomeDetails = true;
      } else if (elementType === "expense") {
        this.showExpenseDetails = true;
      }
    },
    closeDetailView() {
      this.showExpenseDetails = false;
      this.showIncomeDetails = false;
      this.detailId = "";
    },
  },
};
</script>
<style scoped>
.column-1 {
  flex-basis: 75%;
  flex-direction: column;
  white-space: nowrap;
  align-items: flex-start;
}
.column-2 {
  flex-basis: 25%;
  align-items: center;
  justify-content: right;
}
.user {
  font-style: oblique;
  color: var(--blue);
}
</style>
