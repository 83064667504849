export default {
  setLedger(state, ledger) {
    state.ledger = ledger;
  },
  setExpenseDetail(state, expenseDetail) {
    state.expenseDetail = expenseDetail;
  },
  setIncomeDetail(state, incomeDetail) {
    state.incomeDetail = incomeDetail;
  },
  setFilterOptions(state, filterOptions) {
    state.filterOptions = filterOptions;
  },
};
