export default {
  setEarnings(state, earnings) {
    state.earnings = earnings;
  },
  setRecurringEarnings(state, recurringEarnings) {
    state.recurringEarnings = recurringEarnings;
  },
  setLastEarning(state, earning) {
    state.lastEarningsForIncomeSource = earning;
  },
};
