<template>
  <div v-if="loaded">
    <div class="table-title">
      <h2>Earning Details</h2>
    </div>
    <main class="page-content-wrapper">
      <div class="form-group row">
        <label for="amount" class="col-sm-2 col-form-label">Profit:</label>
        <div class="col-sm-10">
          <currency-input :useBootstrap="true" id="amount" v-model="amount" />
        </div>
      </div>
      <div class="form-group row">
        <label
          for="date"
          class="col-sm-2 col-form-label"
          v-if="incomeDetail.recurring"
          >Start Date:</label
        >
        <label for="date" class="col-sm-2 col-form-label" v-else>Date:</label>
        <div class="col-sm-10">
          <date-picker :useBootstrap="true" v-model="dateEarned" />
        </div>
      </div>
      <div class="form-group row" v-if="incomeDetail.recurring">
        <label for="ff" class="col-sm-2 col-form-label">Frequency: </label>
        <div class="col-sm-10">
          <b-form-select
            class="form-control"
            id="ff"
            v-model="ff_id"
            :options="feeFrequencies"
            value-field="id"
            text-field="name"
          >
          </b-form-select>
        </div>
      </div>
      <div class="form-group row">
        <label for="job" class="col-sm-2 col-form-label">Job: </label>
        <div class="col-sm-10">
          <b-form-select
            class="form-control"
            id="job"
            v-model="sourceId"
            :options="incomeSources"
            value-field="id"
            text-field="name"
          >
          </b-form-select>
        </div>
      </div>
      <div class="form-group row">
        <label for="notes" class="col-sm-2 col-form-label">Notes:</label>
        <div class="col-sm-10">
          <b-form-textarea
            id="notes"
            class="form-control"
            v-model="notes"
            placeholder="Optional..."
            rows="3"
            max-rows="4"
            no-resize
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="user" class="col-sm-2 col-form-label">Logged by:</label>
        <div class="col-sm-10">
          <input id="user" class="form-control" :value="enteredBy" readonly />
        </div>
      </div>
      <div class="form-group row">
        <label for="created" class="col-sm-2 col-form-label">Entered on:</label>
        <div class="col-sm-10">
          <input
            id="created"
            class="form-control"
            :value="monthDayYearFormat(incomeDetail.created_date)"
            readonly
          />
        </div>
      </div>
      <div class="form-group row" v-show="isUpdateDateDifferentFromCreatedDate">
        <label for="updated" class="col-sm-2 col-form-label"
          >Modified on:</label
        >
        <div class="col-sm-10">
          <input
            id="updated"
            class="form-control"
            :value="monthDayYearFormat(incomeDetail.updated_date)"
            readonly
          />
        </div>
      </div>
    </main>
    <page-buttons
      v-if="hasMadeChanges"
      rightButtonName="Update"
      leftButtonName="Close"
      @lbClick="$emit('done')"
      @rbClick="updateItem"
    />
    <page-buttons
      v-else
      rightButtonName="Delete"
      leftButtonName="Close"
      @lbClick="$emit('done')"
      @rbClick="deleteItem"
    />
  </div>
  <page-loading v-else />
</template>

<script>
import { mapGetters } from "vuex";
import { monthDayYearFormat } from "../../scriptUtils";
import DatePicker from "../base_ui/DatePicker.vue";
import { BFormSelect, BFormTextarea } from "bootstrap-vue-3";
import CurrencyInput from "../base_ui/CurrencyInput.vue";

export default {
  props: {
    earningId: {
      required: true,
      type: String,
    },
  },
  components: {
    CurrencyInput,
    DatePicker,
    BFormSelect,
    BFormTextarea,
  },
  emits: ["done"],
  computed: {
    ...mapGetters(["incomeDetail", "incomeSources", "feeFrequencies"]),
    hasMadeChanges() {
      return (
        this.amount !== parseFloat(this.incomeDetail.profit) ||
        this.dateEarned !== this.incomeDetail.pay_day ||
        this.sourceId !== this.incomeDetail.is_id ||
        this.notes !== this.incomeDetail.notes ||
        this.ff_id !== this.incomeDetail.ff_id
      );
    },
    enteredBy() {
      if (this.incomeDetail.recurring_fk) {
        return "System Automated Recurring Entry";
      } else {
        return this.incomeDetail.user_name;
      }
    },
    isUpdateDateDifferentFromCreatedDate() {
      return this.incomeDetail.created_date !== this.incomeDetail.updated_date;
    },
    validForm() {
      return this.amount > 0;
    },
  },
  methods: {
    monthDayYearFormat,
    deleteItem() {
      if (
        confirm(
          "Are you sure you want to delete this Earning?\n This action cannot be undone."
        )
      ) {
        this.loaded = false;
        this.$store.dispatch("deleteEarning", {
          id: this.earningId,
          recurring: this.incomeDetail.recurring,
        });
        this.$emit("done");
      }
    },
    updateItem() {
      if (this.validForm) {
        this.loaded = false;
        this.$store.dispatch("updateEarning", {
          earningId: this.earningId,
          incomeSourceId: this.sourceId,
          profit: this.amount,
          payDay: this.dateEarned,
          notes: this.notes,
          ff_id: this.ff_id,
          recurring: this.incomeDetail.recurring,
        });
        this.$emit("done");
      } else {
        this.$store.dispatch("notifyUser", {
          message: "Profit most not be greater than $0",
          level: "danger",
        });
      }
    },
    closeItem() {
      if (
        !this.hasMadeChanges ||
        confirm(
          "You've edited this item. Are you sure you want to exit? Closing will not save your changes."
        )
      ) {
        this.$emit("done");
      }
    },
    initEarningData() {
      this.amount = parseFloat(this.incomeDetail.profit);
      this.sourceId = this.incomeDetail.is_id;
      this.dateEarned = this.incomeDetail.pay_day;
      this.notes = this.incomeDetail.notes;
      this.ff_id = this.incomeDetail.ff_id;
    },
  },
  data() {
    return {
      loaded: false,
      amount: 0,
      dateEarned: new Date(),
      sourceId: "",
      notes: "",
      ff_id: "",
    };
  },
  async created() {
    this.loaded = false;
    await Promise.all([
      this.$store.dispatch("loadSessionDetails"),
      this.$store.dispatch("fetchIncomeDetails", this.earningId),
      this.$store.dispatch("loadIncomeSources"),
      this.$store.dispatch("loadFeeFrequencies"),
    ]);
    this.loaded = true;
    this.initEarningData();
  },
};
</script>

<style scoped>
.page-content-wrapper {
  top: 80px;
}
</style>
