import axios from "axios";
import { headers, handleError } from "../../utils/axiosUtil";

export default {
  loadChargeOptions({ commit, rootGetters }) {
    return axios
      .get(
        "/accounts/" + rootGetters.accountId + "/expenses/chargeOptions",
        headers()
      )
      .then((res) => {
        commit("setChargeOptions", res.data);
      })
      .catch((err) => handleError(err));
  },
  saveChargeOption({ dispatch, rootGetters }, payload) {
    return axios
      .post(
        "/accounts/" + rootGetters.accountId + "/expenses/chargeOptions",
        {
          chargeName: payload.name,
          description: payload.description,
        },
        headers()
      )
      .then(() => {
        dispatch("notifyUser", {
          message: "Payment Method Added",
          level: "success",
        });
        dispatch("loadChargeOptions");
      })
      .catch((err) => handleError(err));
  },
  deleteChargeOption({ dispatch, rootGetters }, payload) {
    return axios
      .patch(
        "/accounts/" +
          rootGetters.accountId +
          "/expenses/chargeOptions/" +
          payload.id,
        {
          deactive: true,
        },
        headers()
      )
      .then(() => {
        dispatch("notifyUser", {
          message: "Payment Method Removed",
          level: "success",
        });
        dispatch("loadChargeOptions");
      })
      .catch((err) => handleError(err));
  },
  loadIncomeSources({ commit, rootGetters }) {
    return axios
      .get(
        "/accounts/" + rootGetters.accountId + "/earnings/sources",
        headers()
      )
      .then((res) => {
        commit("setIncomeSources", res.data);
      })
      .catch((err) => handleError(err));
  },
  saveEarningSource({ dispatch, rootGetters }, payload) {
    return axios
      .post(
        "/accounts/" + rootGetters.accountId + "/earnings/sources",
        {
          sourceName: payload.name,
          description: payload.description,
        },
        headers()
      )
      .then(() => {
        dispatch("notifyUser", {
          message: "Job Added",
          level: "success",
        });
        dispatch("loadIncomeSources");
      })
      .catch((err) => handleError(err));
  },
  deleteEarningSource({ dispatch, rootGetters }, payload) {
    return axios
      .patch(
        "/accounts/" +
          rootGetters.accountId +
          "/earnings/sources/" +
          payload.id,
        {
          deactive: true,
        },
        headers()
      )
      .then(() => {
        dispatch("notifyUser", {
          message: "Job Removed",
          level: "dark",
        });
        dispatch("loadIncomeSources");
      })
      .catch((err) => handleError(err));
  },
};
