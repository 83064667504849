import axios from "axios";
import { headers, handleError } from "../../utils/axiosUtil";

export default {
  loadUpcomingRecurringDetails({ commit, rootGetters }, payload) {
    return axios
      .post(
        "/accounts/" + rootGetters.accountId + "/recurring",
        payload,
        headers()
      )
      .then((res) => {
        commit("setUpcomingRecurring", res.data);
      })
      .catch((err) => handleError(err));
  },
};
