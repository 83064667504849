export default {
  setCurrencies(state, c) {
    state.currencies = c;
  },
  setFeeFrequencies(state, ff) {
    state.feeFrequencies = ff;
  },
  setBudgetQuote(state, quote) {
    state.budgetQuote = quote;
  },
};
