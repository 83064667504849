import axios from "axios";
import { headers, handleError } from "../../utils/axiosUtil";

export default {
  saveExpense({ rootGetters, dispatch }, payload) {
    return axios
      .post(
        "/accounts/" + rootGetters.accountId + "/expenses",
        payload,
        headers()
      )
      .then(() => {
        dispatch("notifyUser", {
          message: "Expense saved",
          level: "success",
        });
        if (payload.recurringExpense) {
          dispatch("loadRecurringExpenses");
        } else {
          dispatch("loadLedgerHistory", rootGetters.storedFilterOptions);
        }
      })
      .catch((err) => handleError(err));
  },
  loadRecurringExpenses({ commit, rootGetters }) {
    return axios
      .get(
        "/accounts/" +
          rootGetters.accountId +
          "/expenses?findNonRecurring=false",
        headers()
      )
      .then((res) => {
        commit("setRecurringExpenses", res.data.recurringExpenses);
      })
      .catch((err) => handleError(err));
  },
  updateExpense({ dispatch, rootGetters }, payload) {
    return axios
      .put(
        "/accounts/" + rootGetters.accountId + "/expenses/" + payload.expenseId,
        payload,
        headers()
      )
      .then(() => {
        dispatch("notifyUser", {
          message: "Expense updated",
          level: "success",
        });
        if (payload.recurring) {
          dispatch("loadRecurringExpenses");
        } else {
          dispatch("loadLedgerHistory", rootGetters.storedFilterOptions);
        }
      })
      .catch((err) => handleError(err));
  },
  deleteExpense({ dispatch, rootGetters }, detail) {
    return axios
      .delete(
        "/accounts/" + rootGetters.accountId + "/expenses/" + detail.id,
        headers()
      )
      .then(() => {
        dispatch("notifyUser", {
          message: "Expense deleted",
          level: "dark",
        });
        if (detail.recurring) {
          dispatch("loadRecurringExpenses");
        } else {
          dispatch("loadLedgerHistory", rootGetters.storedFilterOptions);
        }
      })
      .catch((err) => handleError(err));
  },
};
