import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: false,
  state() {
    return {
      account: {
        user_ids: [],
      },
      user: {},
      accountUsers: [],
    };
  },
  mutations,
  actions,
  getters,
};
