export default {
  earnings(state) {
    return state.earnings;
  },
  recurringEarnings(state) {
    return state.recurringEarnings;
  },
  lastEarnings(state) {
    return state.lastEarningsForIncomeSource;
  },
};
