<template>
  <div v-if="loaded">
    <div class="table-title">
      <h2>Upcoming</h2>
      <div v-if="hasRecurringData">
        Net total:
        <accounting-formated :amount="upcomingRecurring.total" />
      </div>
    </div>
    <main class="page-content-wrapper" v-if="hasRecurringData">
      <ul
        id="responsive-table"
        v-for="dateItem in upcomingRecurring.items"
        :key="dateItem.date"
      >
        <li class="table-header">
          <div class="column column-1">
            {{ weekdayMonthDayFormat(dateItem.date) }}
          </div>
          <div class="column column-2">
            <accounting-formated
              :amount="dateItem.dateTotal"
              :colored="false"
            />
          </div>
        </li>
        <li
          class="table-row"
          v-for="element in dateItem.elements"
          :key="element.id"
          @click="showUpcomingDetail(element, dateItem.date)"
        >
          <div class="column column-1">
            {{ element.category }}
          </div>
          <div class="column column-2">
            <accounting-formated :amount="element.amount" />
          </div>
        </li>
      </ul>
      <pop-up
        v-if="showDetailView"
        @close="showDetailView = false"
        #default="{ closed }"
      >
        <upcoming-details
          :date="upcomingDetail.date"
          :id="upcomingDetail.id"
          :type="upcomingDetail.type"
          :category="upcomingDetail.category"
          :details="upcomingDetail.details"
          :frequency="upcomingDetail.frequency"
          :amount="upcomingDetail.amount"
          @done="closed"
        />
      </pop-up>
    </main>
    <section class="page-content-wrapper" v-else>
      <p>
        No upcoming expenses or earnings. Try adding a
        <router-link to="/recurring/expenses">recurring expense</router-link> or
        <router-link to="/recurring/earnings">recurring earning</router-link>
      </p>
    </section>
  </div>
  <page-loading v-else />
</template>
<script>
import { mapGetters } from "vuex";
import { weekdayMonthDayFormatWithTimezoneOffset } from "../../scriptUtils";
import AccountingFormated from "../../components/base_ui/AccountingFormated.vue";
import PopUp from "../../components/pop_up/PopUp.vue";
import UpcomingDetails from "../../components/pop_up/UpcomingDetails.vue";

export default {
  components: {
    AccountingFormated,
    PopUp,
    UpcomingDetails,
  },
  data() {
    return {
      loaded: false,
      showDetailView: false,
      upcomingDetail: {},
    };
  },
  computed: {
    ...mapGetters(["upcomingRecurring"]),
    hasRecurringData() {
      return Object.keys(this.upcomingRecurring.items).length > 0;
    },
  },
  methods: {
    weekdayMonthDayFormat: weekdayMonthDayFormatWithTimezoneOffset,
    showUpcomingDetail(element, date) {
      const detail = {
        date: date,
        id: element.id,
        type: element.type,
        category: element.category,
        details: element.details,
        frequency: element.frequency,
        amount: element.amount,
      };
      this.upcomingDetail = detail;
      this.showDetailView = true;
    },
  },
  async created() {
    this.loaded = false;
    await this.$store.dispatch("loadSessionDetails");
    await Promise.all([this.$store.dispatch("loadUpcomingRecurringDetails")]);
    this.loaded = true;
  },
};
</script>
<style scoped>
.column-1 {
  flex-basis: 75%;
  flex-direction: column;
  white-space: nowrap;
  align-items: flex-start;
}
.column-2 {
  flex-basis: 25%;
  align-items: center;
  justify-content: right;
}
</style>
