<template>
  <teleport to="body">
    <fa-icon
      id="x-button"
      :icon="['far', 'window-close']"
      size="3x"
      @click="closePopUp"
    ></fa-icon>
    <div class="pop-up-content">
      <slot :closed="closePopUp" />
    </div>
    <blured-background @click="closePopUp" />
  </teleport>
</template>

<script>
import { mapGetters } from "vuex";
import BluredBackground from "../layout/BluredBackground.vue";

export default {
  components: {
    BluredBackground,
  },
  emits: ["close"],
  methods: {
    closePopUp() {
      this.$emit("close");
    },
  },
  computed: {
    ...mapGetters(["sessionAutoExpired"]),
  },
  watch: {
    sessionAutoExpired() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.pop-up-content {
  z-index: 10;
  position: fixed;
  left: 10%;
  top: 10%;
  height: 80%;
  width: 80%;
  border-radius: var(--radius);
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.7);
  padding: 0;
  margin: 0;
  background: linear-gradient(to top, var(--sand) 0%, white 100%);
  overflow-y: auto;
  overflow-x: hidden;
}

#x-button {
  display: block;
  z-index: 6;
  position: fixed;
  top: 10px;
  padding: 15px;
  left: 10px;
  box-sizing: unset;
}
</style>
